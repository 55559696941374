<template>
  <competency-results
    :competency-id="route.params.competencyId as string"
    :student-id="student.id"
    :student-name="student.name"
  />
</template>

<script setup lang="ts">
  import { useRoute } from 'vue-router'
  // import CompetencyPosts from '../components/CompetencyPosts.vue'
  import CompetencyResults from '../components/CompetencyResults.vue'

  defineProps<{ student: User }>()

  const route = useRoute()
</script>
