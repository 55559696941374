<template>
  <div
    class="border border-gray-300"
    :class="{ 'border-sky-500': enabled && current }"
  >
    <rich-text-editor
      :enabled="enabled"
      :current="current"
      :body="post.body"
      @save:post="savePost"
      @set:current="emit('set:current', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
  import { computed, PropType } from 'vue'
  import RichTextEditor from './RichTextEditor.vue'

  const props = defineProps({
    current: {
      type: Boolean,
      default: false,
    },

    enabled: {
      type: Boolean,
      default: true,
    },

    goal: {
      type: Object as PropType<Goal>,
    },

    post: {
      type: Object as PropType<Post>,
      required: true,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const emit = defineEmits(['save:post', 'set:current'])

  const id = computed(() => props.post.id || null)

  const savePost = ({ body }: ChangePost) => {
    const goalId = props.goal?.id || null
    const sessionId = props.session.id
    emit('save:post', { body, goalId, id: id.value, sessionId })
  }
</script>
