<template>
  <div class="p-4 min-h-screen bg-gray-100">
    <div class="m-auto mt-10 max-w-sm">
      <card
        :link-external="loginUrl"
        :link-title="$t('unauthorized.login')"
        class="shadow-2xl"
      >
        <template #title>
          <div class="flex items-center">
            <strong class="flex items-center">
              <span
                class="w-6 h-6 rounded-full border-2 border-black flex items-center flex-col mr-2"
              >
                <forward-icon class="w-4 inline mt-0.5" />
              </span>
              {{ appName }}
            </strong>
            <select
              v-model="$i18n.locale"
              class="ml-auto text-sm appearance-none border px-2 bg-transparent rounded-md"
            >
              <option
                v-for="locale in $i18n.availableLocales"
                :key="`locale-${locale}`"
                :value="locale"
              >
                {{ localeNames[locale as keyof typeof localeNames] }}
              </option>
            </select>
          </div>
        </template>

        <p>{{ $t('unauthorized.requiresLogin') }}</p>

        <ul v-if="isDev" class="my-4">
          <li v-for="idx in [0, 1]" :key="`unauthorized-teacher-${idx}`">
            <a
              class="text-sky-500 underline"
              :href="`${devUrl}/${idx}?lang=${$i18n.locale}`"
            >
              Docent {{ idx + 1 }}
            </a>
          </li>
        </ul>

        <p
          class="text-xs border border-gray-200 p-2 mt-4 rounded-md bg-gray-50"
        >
          {{ $t('unauthorized.avgNotice', { appName }) }}
        </p>
      </card>
    </div>
    <div
      class="flex flex-row items-center justify-center sm:justify-none sm:flex-col gap-1 text-neutral-400 pt-4 sm:absolute text-sm bottom-2 right-3 left-2 sm:left-auto text-center sm:items-end"
    >
      <span class="font-mono sm:text-xs truncate w-24" :title="appVersion">{{
        appVersion
      }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'
  import { computed, inject } from 'vue'

  import Card from '../components/Card.vue'
  import { ForwardIcon } from '@heroicons/vue/20/solid'

  const prodUrl = import.meta.env.VITE_APP_LOGIN_URL
  const devUrl = import.meta.env.VITE_APP_DEV_LOGIN_URL_TEACHER

  const isDev = import.meta.env.DEV
  const isStaging = location.origin.includes('staging')

  const appName = import.meta.env.VITE_APP_NAME
  const appVersion = inject<string>('appVersion')

  const { locale } = useI18n()

  const loginUrl = computed(() => {
    const url = (isDev ? '' : prodUrl) + `?lang=${locale.value}`
    return isStaging ? `${url}&app=staging` : url
  })

  const localeNames = {
    nl: 'Nederlands',
    en: 'English',
  }
</script>
