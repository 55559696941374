<template>
  <new-session-step :step="step" title="Overzicht">
    <h2>Details <a @click="emit('set:step', 0)">Wijzig</a></h2>

    <session-details
      :fields="['title', 'kind', 'context', 'startingAt', 'deadlineAt']"
      :session="session"
    />

    <h2 class="mt-4">Studenten <a @click="emit('set:step', 1)">Wijzig</a></h2>

    <ul>
      <li
        v-for="student in session.students"
        :key="`summary-student-${student.remoteId}`"
      >
        {{ student.name }}
      </li>
    </ul>

    <h2 class="mt-4">Assessoren <a @click="emit('set:step', 2)">Wijzig</a></h2>

    <ul>
      <li
        v-for="teacher in session.teachers"
        :key="`summary-teacher-${teacher.remoteId}`"
      >
        {{ teacher.name }}
      </li>
    </ul>

    <template #actions>
      <a @click="emit('set:step', 2)">Vorige</a>
      <pill-button
        :title="session.id ? 'Bijwerken' : 'Aanmaken'"
        @click="emit('save:session')"
      />
    </template>
  </new-session-step>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import NewSessionStep from './NewSessionStep.vue'
  import PillButton from './PillButton.vue'

  import SessionDetails from './SessionDetails.vue'

  defineProps({
    session: {
      type: Object as PropType<NewSession>,
      required: true,
    },

    step: {
      type: Number,
      required: true,
    },
  })

  const emit = defineEmits(['save:session', 'set:step'])
</script>

<style lang="postcss" scoped>
  h2 {
    @apply flex text-xs mb-4 items-center font-bold;
  }

  h2 a {
    @apply text-xs ml-auto font-normal;
  }

  ul {
    @apply text-sm;
  }
</style>
