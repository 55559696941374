<template>
  <div
    class="fixed w-full h-full top-0 left-0 bg-black/50 overflow-y-auto"
    @click.self="emit('dismiss')"
  >
    <div class="bg-white mt-10 max-w-lg mx-auto p-2">
      <p v-if="student" class="px-2 py-1 border border-gray-100 flex">
        <span class="w-full">{{ student.name }}</span>
        <a @click="student = null">Wijzig</a>
      </p>

      <student-search v-else @select:user="student = $event" />

      <p class="px-2 py-1 border border-gray-100 mt-2">{{ group.title }}</p>

      <p class="p-2 flex">
        <button @click="save">Toevoegen</button>
        <button class="ml-auto" @click="emit('dismiss')">Annuleer</button>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PropType, ref } from 'vue'
  import { useMutation } from 'villus'
  import StudentSearch from '../components/StudentSearch.vue'
  import AddToGroup from '../graphql/AddToGroup.graphql'
  import CreateGroup from '../graphql/CreateGroup.graphql'

  export interface AddToGroupVars {
    userId: string
    groupId?: string
    groupSlug?: string
  }

  const props = defineProps({
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
  })

  const emit = defineEmits(['dismiss', 'add:group', 'add-to:group'])
  const student = ref<null | User>(null)

  const { execute: doCreateGroup } = useMutation(CreateGroup)
  const { execute: doAddToGroup } = useMutation(AddToGroup)

  const addToGroup = (groupId: string, userId: string) => {
    doAddToGroup({ groupId, userId }).then((resp) => {
      const group = resp.data.addToGroup
      emit('add-to:group', { group, user: student.value })
      emit('dismiss')
    })
  }

  const save = () => {
    const user = student.value as User

    if (props.group.id) {
      addToGroup(props.group.id, user.id)
    } else {
      doCreateGroup({ title: props.group.title, slug: props.group.slug }).then(
        (resp) => {
          const group = resp.data.createGroup
          emit('add:group', group)
          addToGroup(group.id, user.id)
        }
      )
    }
  }
</script>
