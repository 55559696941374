<template>
  <new-session-step :step="step" title="Assessment details">
    <div class="space-y-4">
      <p>
        <label>
          Titel
          <input
            autofocus
            name="title"
            placeholder="Bijv. projectgroep of opdrachtgever"
            :value="session.title"
            @input="inputHandler"
          />
        </label>
      </p>

      <p>
        <label>
          Soort
          <select
            class="text-gray-400"
            name="kind"
            :class="{ 'text-black': session.kind.length }"
            :value="session.kind"
            @input="inputHandler"
          >
            <option value="">Soort assessment…</option>
            <option v-for="kind in kinds" :key="kind" :value="kind">
              {{ $t(`entities.session.kinds.${kind}`) }}
            </option>
          </select>
        </label>
      </p>

      <p>
        <label>
          Context
          <input
            name="context"
            placeholder="Bijv. propedeuse of minor"
            :value="session.context"
            @input="inputHandler"
          />
        </label>
      </p>

      <div class="grid grid-cols-2 gap-4">
        <p>
          <label>
            Assessmentdatum
            <input
              name="startingAt"
              placeholder="Datum…"
              type="datetime-local"
              :value="session.startingAt"
              @input="startingAtHandler"
            />
          </label>
        </p>

        <p>
          <label>
            Inleverdeadline
            <input
              name="deadlineAt"
              placeholder="Datum…"
              type="datetime-local"
              :value="session.deadlineAt"
              @input="inputHandler"
            />
          </label>
        </p>
      </div>

      <p>
        <label
          class="text-xs bg-white py-1 px-2 border-gray-300 border block cursor-pointer"
        >
          <span class="flex items-center space-x-2">
            <input
              name="includeInExport"
              type="checkbox"
              :checked="session.includeInExport"
              @input="includeInExportHandler"
            />
            <span>Toevoegen aan export</span>
          </span>
        </label>
      </p>
    </div>

    <template #actions>
      <router-link
        v-if="session.id"
        :to="{ name: 'session', params: { id: session.id } }"
      >
        Annuleren
      </router-link>
      <router-link v-else :to="{ name: 'sessions' }">Annuleren</router-link>
      <pill-button
        :disabled="!valid"
        title="Volgende stap"
        @click="emit('set:step', 1)"
      />
    </template>
  </new-session-step>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue'
  import NewSessionStep from './NewSessionStep.vue'
  import PillButton from './PillButton.vue'
  import dayjs from 'dayjs'

  const props = defineProps({
    session: {
      type: Object as PropType<NewSession>,
      required: true,
    },

    step: {
      type: Number,
      required: true,
    },
  })

  const startingAtHandler = (event: Event) => {
    inputHandler(event)

    const startingAt = dayjs(props.session.startingAt)
    const deadlineAt = startingAt.subtract(1, 'week')
    const value = deadlineAt.format('YYYY-MM-DD[T]HH:mm')

    emit('update:value', { field: 'deadlineAt', value })
  }

  const includeInExportHandler = (event: Event) => {
    const target = event.target as HTMLInputElement
    const field = target.name as keyof NewSession
    const value = !props.session.includeInExport

    emit('update:value', { field, value })
  }

  const inputHandler = (event: Event) => {
    const target = event.target as HTMLInputElement
    const field = target.name as keyof NewSession
    const value = target.value

    emit('update:value', { field, value })
  }

  const kinds = [
    'integral',
    'practice',
    'individual',
    'internship',
    'graduation',
  ]

  const emit = defineEmits(['update:value', 'set:step'])

  const valid = computed(() => {
    return ['title', 'kind', 'startingAt'].every((field) => {
      const value = props.session[field as keyof NewSession] as string | []
      return value && value.length
    })
  })
</script>
