<template>
  <div v-if="isDone">
    <h2 class="text-xl mt-4 mb-2">{{ data.competency.title }}</h2>

    <div v-if="data.competencyResults.length">
      <competency-post
        v-for="post in competencyPosts"
        :key="post.id"
        :post="post"
      />
    </div>

    <div v-else class="space-y-2">
      <p>{{ $t('posts.none') }}</p>
      <p>
        <a
          class="text-sky-500 cursor-pointer flex items-center"
          @click="router.back"
        >
          <arrow-left-icon class="w-4 mr-1" />
          <span>{{ $t('actions.back') }}</span>
        </a>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { v4 as uuid } from 'uuid'

  import { computed } from 'vue'
  import { useRouter } from 'vue-router'
  import { useHead } from '@vueuse/head'
  import { useQuery } from 'villus'

  import { ArrowLeftIcon } from '@heroicons/vue/24/solid'

  import query from '../graphql/CompetencyResults.graphql'
  import CompetencyPost from '../components/CompetencyPost.vue'

  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()

  const props = defineProps({
    studentId: {
      type: String,
      required: true,
    },

    studentName: {
      type: String,
      default: () => null,
    },

    competencyId: {
      type: String,
      required: true,
    },
  })

  const router = useRouter()

  const variables = computed(() => props)
  const { isDone, data } = useQuery({ query, variables })

  const competencyPosts = computed(() => {
    if (data.value) {
      return data.value.competencyResults.flatMap((r: Result) => {
        const goal = JSON.parse(JSON.stringify(r.goal))
        delete goal.posts

        if (r.goal && r.goal.posts.length) {
          return r.goal.posts.map((p) => {
            p.goal = goal
            return p
          })
        } else {
          return [
            {
              body: t('posts.empty'),
              id: uuid(),
              goal,
            },
          ]
        }
      })
    } else {
      return []
    }
  })

  useHead({
    title: () =>
      data.value
        ? `${data.value.competency?.title} - ${props.studentName}`
        : null,
  })
</script>
