<template>
  <new-session-step :step="step" title="Studenten">
    <new-session-users
      field="students"
      :session="session"
      @update:value="emit('update:value', $event)"
    />

    <template #actions>
      <a @click="emit('set:step', 0)">Vorige</a>
      <pill-button title="Volgende stap" @click="emit('set:step', 2)" />
    </template>
  </new-session-step>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import NewSessionStep from './NewSessionStep.vue'
  import NewSessionUsers from './NewSessionUsers.vue'
  import PillButton from './PillButton.vue'

  defineProps({
    session: {
      type: Object as PropType<NewSession>,
      required: true,
    },

    step: {
      type: Number,
      required: true,
    },
  })

  const emit = defineEmits(['update:value', 'set:step'])
</script>
