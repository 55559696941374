<template>
  <div
    class="py-3 px-6 bg-white border-gray-300 border rounded-md"
    :class="{ 'hover:shadow-lg cursor-pointer': requirement.competency }"
    @click="goToCompetency"
  >
    <h3 class="text-lg">
      {{
        (locale === 'en'
          ? requirement.competency?.titleEn
          : requirement.competency?.title) || $t('programs.freeSpace')
      }}
    </h3>
    <p class="mb-2 text-sm">Nv. {{ requirement.level }}</p>

    <div>
      <p class="mb-2">
        <span class="text-2xl">
          {{ requirement.amountDone }}
        </span>
        /{{ requirement.amount }} ec
      </p>

      <program-progress-bar
        :amount-done="(requirement.amountDone || 0) / 3"
        :amount-remaining="amountRemaining / 3"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, ref } from 'vue'
  import ProgramProgressBar from './ProgramProgressBar.vue'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'

  const { locale } = useI18n()

  const props = defineProps({
    requirement: {
      type: Object as PropType<ProgramRequirement>,
      required: true,
    },

    studentId: {
      type: String,
      required: true,
    },
  })

  const amountDone = props.requirement.amountDone || 0
  const amountRemaining = ref(
    Math.max(0, props.requirement.amount - amountDone),
  )

  const router = useRouter()

  const goToCompetency = () => {
    if (props.requirement.competency) {
      router.push({
        name: 'student_competency',
        params: {
          studentId: props.studentId,
          competencyId: props.requirement.competency?.id,
        },
      })
    }
  }
</script>
