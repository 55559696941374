<template>
  <icon-button
    :icon="Bars3CenterLeftIcon"
    @click="emit('show:sidebar', !showSidebar)"
  />
</template>

<script lang="ts" setup>
  import { Bars3CenterLeftIcon } from '@heroicons/vue/24/outline'
  import IconButton from './IconButton.vue'

  defineProps({
    showSidebar: {
      type: Boolean,
      required: true,
    },
  })

  const emit = defineEmits(['show:sidebar'])
</script>
