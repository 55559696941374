<template>
  <div class="p-6 md:pt-20 m-auto max-w-4xl">
    <h1 class="text-2xl first-letter:uppercase">
      {{ $t('entities.session.singular') }}
    </h1>

    <section v-if="isFetching" class="hidden">
      {{ $t('system.loading') }}
    </section>

    <section v-else-if="enabled" class="space-y-4 mt-4">
      <h2 class="text-lg">{{ $t('studentSession.submitGoals') }}</h2>

      <session-deadline-banner
        v-if="!data.session.finalized"
        :session="data.session"
        @is:overdue="overdue = $event"
      />

      <student-goals
        :competencies="competencies"
        :enabled="enabled"
        :me="me"
        :session="data.session"
        @create:goals="createGoals"
        @dismiss="router.back"
      />
    </section>

    <section v-else-if="posts.length">
      <h2 class="text-lg">{{ $t('entities.goal.feedback') }}</h2>

      <competency-post
        v-for="post in posts"
        :key="`post-${post.id}`"
        :post="post"
        :show-session-details="false"
      />
    </section>

    <section v-else>
      <h2 class="text-lg">{{ $t('studentSession.pendingResults') }}</h2>
      <p>{{ $t('studentSession.pendingExplanation') }}</p>
    </section>
  </div>
</template>

<script lang="ts" setup>
  import { useQuery, useMutation } from 'villus'
  import { PropType, computed, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  import query from '../graphql/Session.graphql'
  import CreateGoals from '../graphql/CreateGoals.graphql'

  import SessionDeadlineBanner from './SessionDeadlineBanner.vue'
  import StudentGoals from './StudentGoals.vue'
  import CompetencyPost from './CompetencyPost.vue'

  defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const emit = defineEmits(['set:flash'])

  const route = useRoute()
  const router = useRouter()

  const sessionId = route.params.sessionId
  const variables = computed(() => {
    return { id: sessionId }
  })

  const { isDone, isFetching, data } = useQuery({ query, variables })

  const overdue = ref(false)
  const enabled = computed(() => {
    return isDone.value && !overdue.value && data.value.session.enabled
  })

  const posts = computed(() => {
    if (!isDone.value) {
      return []
    }

    const posts: Post[] = data.value.session.posts.map((post: Post) => {
      const goals = data.value.session.goals as Goal[]
      const goal = goals.find((g) => g.id === post.goal?.id)

      if (goal) {
        post.goal = goal
      }

      return post
    })

    return posts.sort((a, b) => {
      const [aScore, bScore] = [a, b].map((p) => {
        return p?.goal?.competency?.position || -1
      })

      return aScore - bScore
    })
  })

  const { execute } = useMutation(CreateGoals)
  const createGoals = (sessionGoals: NewGoals) => {
    execute(sessionGoals).then(({ error, data }) => {
      if (error) {
        emit('set:flash', { kind: 'error', msg: error.message })
      } else {
        emit('set:flash', { kind: 'notice', msg: 'Leerdoelen opgeslagen.' })

        sessionGoals.goals = data.createGoals.map((goal: Goal) => {
          return {
            competencyId: goal.competency.id,
            amount: goal.amount,
            id: goal.id,
            level: goal.level,
            description: goal.description,
          }
        })
      }
    })
  }
</script>
