<template>
  <div
    class="fixed w-full h-full top-0 left-0 bg-black/50 overflow-y-auto z-20"
  >
    <div class="bg-white my-10 max-w-lg mx-auto p-4">
      <h2 class="text-xl font-bold">Leerdoelen wijzigen</h2>
      <h3 class="text-lg mb-4">{{ student.name }}</h3>

      <student-goals
        :me="student"
        :session="session"
        :competencies="competencies"
        :enabled="!session.finalized"
        :show-templates="true"
        @create:goals="createGoals"
        @dismiss="emit('dismiss')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useMutation } from 'villus'
  import { PropType } from 'vue'
  import StudentGoals from './StudentGoals.vue'
  import CreateGoals from '../graphql/CreateGoals.graphql'

  const props = defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },

    student: {
      type: Object as PropType<User>,
      required: true,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const emit = defineEmits(['dismiss', 'set:flash', 'set:goals'])

  const { execute } = useMutation(CreateGoals)
  const createGoals = (sessionGoals: NewGoals) => {
    sessionGoals.studentId = props.student.id

    execute(sessionGoals).then(({ error, data }) => {
      if (error) {
        emit('set:flash', { kind: 'error', msg: error.message })
      } else {
        emit('dismiss')
        emit('set:goals', data.createGoals)
        emit('set:flash', { kind: 'notice', msg: 'Leerdoelen opgeslagen.' })
        sessionGoals.goals = data.createGoals
      }
    })
  }
</script>
