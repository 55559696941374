<template>
  <ul class="w-full">
    <user-search-result
      v-for="user in users"
      :me="me"
      :removable="removable"
      :user="user"
      @select:user="emit('select:user', $event)"
      @remove:user="emit('remove:user', $event)"
    />
  </ul>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import UserSearchResult from "./UserSearchResult.vue";

defineProps({
  me: {
    type: Object as PropType<User>,
  },

  removable: {
    type: Boolean,
    default: false,
  },

  users: {
    type: Array as PropType<User[]>,
    default: [],
  },
});

const emit = defineEmits(["select:user", "remove:user"]);
</script>
