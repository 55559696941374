<template>
  <div class="py-2 px-4 border border-gray-200">
    <div v-if="isNewRecord">
      <p v-if="!isAllowed">
        Je moet toestemming geven om de microfoon te kunnen gebruiken.
      </p>

      <div v-else>
        <template v-if="isSupported">
          <template v-if="isPreviewing">
            <p>
              <template v-if="previewingSupported">
                <audio v-if="audioUrl" :src="audioUrl" controls />
              </template>
              <template v-else>
                Terugluisteren wordt niet ondersteund in Safari.
              </template>
            </p>

            <p class="space-x-2">
              <icon-button
                class="ml-auto"
                title="Opslaan"
                :icon="ArrowDownTrayIcon"
                @click="finalizeAttachment"
              />

              <icon-button
                title="Verwijderen"
                :icon="TrashIcon"
                @click="emit('delete:attachment', idx)"
              />
            </p>
          </template>

          <icon-button
            v-else-if="isRecording"
            title="Stop opname"
            :icon="StopIcon"
            @click="stopRecording"
          />

          <icon-button
            v-else
            title="Start opname"
            :icon="MicrophoneIcon"
            @click="startRecording"
          />
        </template>

        <template v-else>
          Sorry, je browser ondersteund geen nieuwe opnames.
        </template>
      </div>
    </div>

    <div v-else class="flex align-middle">
      Opname
      <template v-if="attachment.insertedAt">
        van {{ formattedTimestamp(attachment.insertedAt) }}
      </template>

      <span class="ml-auto space-x-2">
        <icon-button
          v-if="isFinalized"
          title="Downloaden"
          :icon="CloudArrowDownIcon"
          @click="downloadAttachment"
        />

        <span v-else>Conversie loopt nog.</span>

        <icon-button
          title="Verwijderen"
          :icon="TrashIcon"
          @click="emit('delete:attachment', idx)"
        />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, onUnmounted, PropType, ref } from 'vue'
  import { formattedTimestamp } from '../utils'

  import IconButton from './IconButton.vue'
  import {
    CloudArrowDownIcon,
    MicrophoneIcon,
    ArrowDownTrayIcon,
    StopIcon,
    TrashIcon,
  } from '@heroicons/vue/24/outline'

  import AppendToAttachment from '../graphql/AppendToAttachment.graphql'
  import FinalizeAttachment from '../graphql/FinalizeAttachment.graphql'
  import DownloadAttachment from '../graphql/DownloadAttachment.graphql'

  import { useMutation, useQuery } from 'villus'

  const props = defineProps({
    attachment: {
      type: Object as PropType<Attachment>,
      required: true,
    },

    goal: {
      type: Object as PropType<Goal>,
      required: true,
    },

    idx: {
      type: Number as PropType<number>,
      required: true,
    },

    post: {
      type: Object as PropType<Post>,
      required: true,
    },
  })

  const emit = defineEmits(['delete:attachment', 'finalize:attachment'])

  const { execute: appendAttachment } = useMutation(AppendToAttachment)

  const isSafari = (navigator.vendor.match(/apple/i) || '').length > 0

  const isFinalized = ref(props.attachment.finalized)
  const isNewRecord = ref(props.attachment.newRecord)
  const isAllowed = ref(false)
  const isSupported = ref(false)
  const isRecording = ref(false)
  const isPreviewing = ref(false)
  const previewingSupported = !isSafari
  const audioUrl = ref<string | null>(null)

  let mediaRecorder: MediaRecorder | null = null
  const codec = 'codecs=opus'
  const constraints = { audio: true }
  let blob: Blob | null = null
  let chunks: BlobPart[] = []
  const fileReader = new FileReader()

  const startRecording = () => {
    if (mediaRecorder) {
      isRecording.value = true
      mediaRecorder.start(2000)
    }
  }

  const stopRecording = () => {
    if (mediaRecorder) {
      isRecording.value = false
      mediaRecorder.stop()
    }
  }

  const previewRecording = () => {
    blob = new Blob(chunks, { type: `${props.attachment.mimeType};${codec}` })
    chunks = []
    audioUrl.value = URL.createObjectURL(blob)
    isPreviewing.value = true
  }

  const initializeRecorder = () => {
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      isAllowed.value = true
      mediaRecorder = new MediaRecorder(stream)
      mediaRecorder.onstop = previewRecording
      mediaRecorder.ondataavailable = (e) => {
        const id = props.attachment.id,
          blob = new Blob([e.data])

        fileReader.readAsDataURL(blob)
        fileReader.onloadend = function () {
          if (typeof fileReader.result === 'string') {
            const variables = { id, blob: fileReader.result }
            appendAttachment(variables)
          }
        }

        chunks.push(e.data)
      }
    })
  }

  const finalizedTimeout = 1500
  const finalizedInterval = ref<number>(0)

  const { execute: doFinalizeAttachment } = useMutation(FinalizeAttachment)
  const finalizeAttachment = () => {
    const variables = { id: props.attachment.id }
    doFinalizeAttachment(variables).then(({ error }) => {
      isPreviewing.value = false
      isNewRecord.value = false
      emit('finalize:attachment')

      finalizedInterval.value = setInterval(checkFinalized, finalizedTimeout)

      if (error) {
        alert(error.message)
      }
    })
  }

  const variables = { id: props.attachment.id }
  const query = DownloadAttachment
  const fetchOnMount = false
  const { execute } = useQuery({ query, fetchOnMount, variables })

  const checkFinalized = () => {
    execute().then(({ data }) => {
      if (data.attachment.fileUrl) {
        isFinalized.value = true
        clearInterval(finalizedInterval.value)
      }
    })
  }

  const downloadAttachment = () => {
    execute().then(({ data }) => {
      open(data.attachment.fileUrl)
    })
  }

  onMounted(() => {
    if (props.attachment.newRecord && navigator.mediaDevices) {
      isSupported.value = true
      initializeRecorder()
    }

    if (!isFinalized.value && !isNewRecord.value) {
      finalizedInterval.value = setInterval(checkFinalized, finalizedTimeout)
    }
  })

  onUnmounted(() => {
    clearInterval(finalizedInterval.value)
  })
</script>
