<template>
  <tr>
    <td class="py-4 px-3 align-top">
      <user-name :user="row.student" :show-name="true" />
    </td>

    <td
      v-for="(goals, idx) in row.goals"
      :key="`col-${idx}`"
      class="py-3 align-top"
    >
      <template v-for="(goal, goalIdx) in goals">
        <result-picker
          v-if="goal"
          :key="`result-picker-${idx}-${goalIdx}`"
          :is-enabled="session.enabled"
          :goal="goal"
          @create:result="emit('create:result', $event)"
          @delete:result="emit('delete:result', $event)"
        >
          <session-table-goal
            :amount="goal.amount"
            :level="goal.level"
            :result="goal.result"
          />
        </result-picker>

        <div v-else :key="`col-${goalIdx}-no-goal`" class="block h-8"></div>
      </template>
    </td>

    <td class="py-3 pl-3 space-y-1 align-top">
      <session-table-goal
        v-for="level in levels"
        :key="`level-${level}`"
        :amount="totalAmount(level)"
        :result="totalResult(level)"
      />
    </td>

    <td class="align-top pt-5">
      <icon-button
        v-if="session.enabled"
        :icon="PencilSquareIcon"
        class="ml-auto p-0 text-gray-400"
        @click="emit('edit:student', row.student)"
      />
    </td>
  </tr>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue'
  import UserName from './UserName.vue'
  import SessionTableGoal from './SessionTableGoal.vue'
  import ResultPicker from './ResultPicker.vue'
  import IconButton from './IconButton.vue'
  import { PencilSquareIcon } from '@heroicons/vue/24/solid'

  import { uniqObjValues } from '../utils.js'

  const props = defineProps({
    row: {
      type: Object as PropType<SessionTableRowData>,
      required: true,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const emit = defineEmits(['create:result', 'delete:result', 'edit:student'])

  const levels = computed(() => {
    const objects = props.row.goals.flat() as Array<Object>
    return uniqObjValues(objects, 'level')
  })

  const totalAmount = (level: string): number => {
    return props.row.goals
      .flat()
      .filter((goal) => goal?.level === level)
      .reduce((sum, goal) => sum + goal?.amount, 0)
  }

  const totalResult = (level: string): Result | null => {
    const goalsWithResult = props.row.goals
      .flat()
      .filter((goal) => goal?.level === level && goal?.result)

    if (goalsWithResult.length) {
      const amount = goalsWithResult.reduce((sum, goal) => {
        const result = goal?.result
        if (result?.amount) {
          return sum + result.amount
        } else {
          return sum
        }
      }, 0)
      return { amount, level }
    } else {
      return null
    }
  }
</script>
