<template>
  <tr>
    <td :colspan="colspan"></td>
    <td class="text-right">Totaal</td>
    <td class="pl-3">
      <session-table-goal :amount="amount" :result="result" />
    </td>
    <td class="pl-2 pr-3">EC</td>
  </tr>
</template>

<script lang="ts" setup>
  import { computed, ref, PropType } from 'vue'
  import SessionTableGoal from './SessionTableGoal.vue'

  const props = defineProps({
    session: {
      type: Object as PropType<Session>,
      required: true,
    },

    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },
  })

  const colspan = ref(props.competencies.length)

  const amount = computed(() => {
    return props.session.goals.reduce((sum, goal) => {
      return sum + goal.amount
    }, 0)
  })

  const result = computed(() => {
    const goalsWithResult = props.session.goals.filter((goal) => goal?.result)

    if (goalsWithResult.length) {
      const amount = goalsWithResult.reduce((sum, goal) => {
        return sum + goal.result?.amount
      }, 0)
      return { amount }
    } else {
      return null
    }
  })
</script>
