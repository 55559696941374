<template>
  <new-session-step :step="step" title="Assessoren">
    <new-session-users
      field="teachers"
      :me="me"
      :session="session"
      @update:value="emit('update:value', $event)"
    />

    <template v-slot:actions>
      <a @click="emit('set:step', 1)">Vorige</a>
      <pill-button @click="emit('set:step', 3)" title="Volgende stap" />
    </template>
  </new-session-step>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import NewSessionStep from "./NewSessionStep.vue";
import NewSessionUsers from "./NewSessionUsers.vue";
import PillButton from "./PillButton.vue";

defineProps({
  session: {
    type: Object as PropType<NewSession>,
    required: true,
  },

  me: {
    type: Object as PropType<User>,
  },

  step: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["update:value", "set:step"]);
</script>
