<template>
  <header :class="state" class="flex items-center text-xl">
    <span v-if="showName" class="mr-6">{{ goal.student.name }}</span>
    <span v-else-if="showCompetency" class="mr-6">{{
      locale === 'nl' ? goal.competency?.title : goal.competency?.titleEn
    }}</span>

    <div id="amount" class="font-mono text-base text-sky-500">
      <template v-if="goal.result && goal.result.amount !== null">
        {{ goal.result.amount }}/</template
      >{{ goal.amount }}EC
    </div>

    <div
      id="level"
      class="inline-block py-2 ml-3 w-8 font-mono text-xs text-center text-white rounded-full bg-sky-500"
    >
      N{{ goal.level }}
    </div>
  </header>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import { useI18n } from 'vue-i18n'

  const { locale } = useI18n()

  defineProps({
    goal: {
      type: Object as PropType<Goal>,
      required: true,
    },

    showCompetency: {
      type: Boolean,
      default: () => false,
    },

    showName: {
      type: Boolean,
      default: true,
    },

    state: {
      type: String as PropType<string | null>,
    },
  })
</script>

<style lang="postcss" scoped>
  .success #amount {
    @apply text-lime-500;
  }

  .success #level {
    @apply bg-lime-500;
  }

  .fail #amount {
    @apply text-red-500;
  }

  .fail #level {
    @apply bg-red-500;
  }

  .semi-fail #amount {
    @apply text-orange-500;
  }

  .semi-fail #level {
    @apply bg-orange-500;
  }
</style>
