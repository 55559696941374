<template>
  <div>
    <div class="space-y-4 mt-4">
      <div v-for="group in groupsWithStudents" :key="`group-${group.id}`">
        <h2 class="font-bold mb-4 flex">
          <span class="w-full">{{ group.title }}</span>
          <!--<a @click="addToGroup = group">+</a>-->
          <icon-button :icon="PlusCircleIcon" @click="addToGroup = group" />
        </h2>

        <div
          v-if="group.students.length"
          class="grid gap-2 md:grid-cols-2 lg:grid-cols-3"
        >
          <card
            v-for="student in group.students"
            :key="`group-student-${student.id}`"
            link-title="Voortgang bekijken"
            :link-route="{ name: 'student', params: { studentId: student.id } }"
          >
            <template #title>{{ student.name }}</template>
          </card>
        </div>

        <div v-else>
          <p>Geen studenten.</p>
        </div>
      </div>
    </div>

    <AddToGroup
      v-if="addToGroup"
      :group="addToGroup"
      @dismiss="addToGroup = null"
      @add:group="emit('add:group', $event)"
      @add-to:group="emit('add-to:group', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed, ref } from 'vue'
  import Card from '../components/Card.vue'
  import AddToGroup from '../components/AddToGroup.vue'

  import { PlusCircleIcon } from '@heroicons/vue/20/solid'
  import IconButton from '../components/IconButton.vue'

  const emit = defineEmits(['add:group', 'add-to:group'])

  const props = defineProps({
    defaultGroups: {
      required: true,
      type: Array as PropType<Group[]>,
    },

    groups: {
      type: Array as PropType<Group[]>,
      required: true,
    },
  })

  const addToGroup = ref<null | Group>(null)

  const groupsWithStudents = computed(() => {
    const defaultGroups = props.defaultGroups
      .map((group) => {
        const existingGroup = props.groups.find((g) => g.slug === group.slug)
        if (existingGroup) {
          return existingGroup
        } else {
          group.students = []
          return group
        }
      })
      .sort((a, b) => (a.title < b.title ? -1 : 1))

    const remainingGroups = props.groups
      .filter((group) => {
        const groupSlugs = props.defaultGroups.map((g) => g.slug)
        return !groupSlugs.includes(group.slug) && group.students.length
      })
      .sort((a, b) => (a.title < b.title ? -1 : 1))

    return defaultGroups.concat(remainingGroups)
  })
</script>
