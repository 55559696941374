<template>
  <li
    class="border-t border-t-gray-200 first:border-t-0 py-2 rounded-sm"
    :class="{
      'px-4': !removable,
      'cursor-pointer': !removable,
      'hover:bg-sky-100': !removable,
    }"
    @click="emit('select:user', user)"
  >
    <span class="flex">
      {{ user.name }}

      <span
        v-if="removable && me !== user"
        class="ml-auto text-gray-300 w-6 text-center cursor-pointer"
        @click="emit('remove:user', user)"
      >
        <x-circle-icon />
      </span>
    </span>

    <span v-if="user.studentId" class="block text-xs text-gray-400">{{
      user.studentId
    }}</span>
  </li>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import { XCircleIcon } from '@heroicons/vue/24/solid'

  defineProps({
    me: {
      type: Object as PropType<User>,
    },

    removable: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const emit = defineEmits(['select:user', 'remove:user'])
</script>
