<template>
  <div class="space-y-1">
    <component
      :is="componentName(attachment)"
      v-for="(attachment, idx) in post.attachments"
      :key="`attachment-${attachment.id}`"
      :attachment="attachment"
      :post="post"
      :goal="goal"
      :idx="idx"
      @delete:attachment="deleteAttachment($event as number)"
      @finalize:attachment="allowNewAttachments = true"
    />

    <div
      v-if="enabled && allowNewAttachments"
      class="text-sm opacity-40 hover:opacity-100 text-center text-gray-400 border rounded-md p-1 cursor-pointer"
      @click="createAttachment"
    >
      Opname toevoegen
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PropType, ref } from 'vue'
  import SessionGoalAudioAttachment from './SessionGoalAudioAttachment.vue'
  import SessionGoalUnknownAttachment from './SessionGoalUnknownAttachment.vue'

  type AttachmentKind =
    | typeof SessionGoalUnknownAttachment
    | typeof SessionGoalAudioAttachment

  const props = defineProps({
    enabled: {
      type: Boolean,
      required: true,
    },

    goal: {
      type: Object as PropType<Goal>,
    },

    post: {
      type: Object as PropType<Post>,
      required: true,
    },
  })

  const componentName = (attachment: Attachment) => {
    let cmp: AttachmentKind = SessionGoalUnknownAttachment

    switch (attachment.mimeType) {
      case 'audio/ogg':
        cmp = SessionGoalAudioAttachment
        break
    }

    return cmp
  }

  const emit = defineEmits([
    'add:attachment',
    'create:attachment',
    'delete:attachment',
  ])

  const allowNewAttachments = ref(true)

  const createAttachment = () => {
    allowNewAttachments.value = false

    const mimeType = 'audio/ogg'
    emit('create:attachment', {
      postId: props.post.id,
      mimeType,
    })
  }

  const deleteAttachment = (idx: number) => {
    if (confirm('Zeker weten?')) {
      emit('delete:attachment', {
        postId: props.post.id,
        idx,
      })
    }
  }
</script>
