<template>
  <div class="fixed top-0 left-0 z-20 w-full h-full" @click="emit('dismiss')" />
  <div class="absolute z-20 -mt-10 bg-white rounded-md shadow-xl">
    <ul class="font-mono text-xs text-gray-500">
      <li
        v-for="amount in amounts"
        :key="`picker-option-${amount}`"
        class="py-2 px-4 pr-11 cursor-pointer hover:bg-sky-100 rounded-md"
        @click="setResult(amount)"
      >
        <session-table-goal :level="goal.level" :amount="amount" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
  import { computed, PropType } from 'vue'
  import SessionTableGoal from './SessionTableGoal.vue'

  const props = defineProps({
    goal: {
      type: Object as PropType<Goal>,
      required: true,
    },
  })

  const emit = defineEmits(['create:result', 'dismiss'])

  const setResult = (amount: number | null) => {
    emit('create:result', {
      goalId: props.goal.id,
      amount: amount,
      level: props.goal.level,
    })
  }

  const amounts = computed(() => {
    return [null, 0, props.goal.competency.amounts].flat()
  })
</script>
