<template>
  <h2
    v-if="sessions.length && kind === 'past'"
    class="mb-2 text-sm font-bold flex"
  >
    <span>{{ $t('sessions.completed') }}</span>
    <a
      v-if="kind === 'past' && isCollapsed && sessions.length >= collapsedCount"
      class="ml-auto font-normal underline text-sky-500 cursor-pointer"
      @click="isCollapsed = false"
      >{{ $t('sessions.showAll') }}</a
    >
  </h2>

  <div class="grid gap-2 md:grid-cols-2 lg:grid-cols-3">
    <session-list-card
      v-for="session in filteredSessions"
      :key="`session-list-${session.id}`"
      :me="me"
      :session="session"
    />
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed, ref } from 'vue'

  import { useQuery } from 'villus'
  import type { DocumentNode } from 'graphql'

  import PastSessions from '../graphql/PastSessions.graphql'
  import UpcomingSessions from '../graphql/UpcomingSessions.graphql'

  import SessionListCard from './SessionListCard.vue'

  const props = defineProps({
    kind: {
      required: true,
      type: String as PropType<'upcoming' | 'past'>,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  let query: DocumentNode = UpcomingSessions
  if (props.kind === 'past') {
    query = PastSessions
  }

  const { data, isFetching } = useQuery({ query })

  const collapsedCount = 6
  const isCollapsed = ref(true)

  const filteredSessions = computed(() => {
    if (props.kind === 'past' && isCollapsed.value) {
      return sessions.value.slice(0, collapsedCount)
    } else {
      return sessions.value
    }
  })

  const sessions = computed<Session[]>(() => {
    if (isFetching.value) {
      return []
    }
    return data.value[`${props.kind}Sessions`]
  })
</script>
