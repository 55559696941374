<template>
  <card link-title="Exporteer" @link-clicked="submitForm">
    <template #title>Exporteer resultaten</template>

    <form class="flex flex-col space-y-2" @submit.prevent="exportResults">
      <label>
        Vanaf
        <input v-model="exportFrom" type="datetime-local" required />
      </label>

      <label>
        Tot
        <input v-model="exportTo" type="datetime-local" required />
      </label>

      <button ref="submitButton" class="hidden">Submit</button>
    </form>
  </card>
</template>

<script setup lang="ts">
  import { computed, reactive, ref } from 'vue'
  import { timestampToISO } from '../utils'

  import Card from './Card.vue'

  import { useMutation } from 'villus'
  import ExportResults from '../graphql/ExportResults.graphql'

  const { execute } = useMutation(ExportResults)

  const submitButton = ref<HTMLButtonElement | null>(null)

  const exportFrom = ref<null | string>(null)
  const exportTo = ref<null | string>(null)

  const exportFromIso = computed(() => timestampToISO(exportFrom.value))
  const exportToIso = computed(() => timestampToISO(exportTo.value))

  const variables = reactive({
    to: exportToIso,
    from: exportFromIso,
  })

  const exportResults = () => {
    execute(variables).then(({ data }) => {
      window.location.href = data.exportResults.fileUrl
    })
  }

  const submitForm = () => submitButton.value?.click()
</script>

<style lang="postcss" scoped>
  label input {
    @apply block px-2 pt-1 bg-gray-100 rounded-md w-full;
  }
</style>
