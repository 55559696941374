<template>
  <section class="p-4 md:p-14 md:ml-60 md:min-h-screen md:bg-gray-100">
    <h2
      class="first-letter:uppercase mb-4 text-xl md:mt-4 md:mb-14 md:text-4xl"
    >
      <slot name="title" />
    </h2>

    <slot />
  </section>
</template>

<script lang="ts" setup></script>
