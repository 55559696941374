<template>
  <div>
    <h3 class="text-xl">
      {{ locale === 'en' ? program.titleEn : program.title }}
    </h3>

    <div class="flex items-center gap-2 text-sm mt-2 mb-6 space-x-2">
      <span class="whitespace-nowrap">{{
        $t('programs.amountDone', { amount: amountDone })
      }}</span>

      <program-progress-bar
        :amount-done="amountDone / unitSize"
        :amount-remaining="amountRemaining / unitSize"
      />

      <span class="whitespace-nowrap">
        {{ $t('programs.amountRemaining', { amount: amountRemaining }) }}</span
      >
    </div>

    <div class="grid gap-4 md:grid-cols-3 2xl:grid-cols-4">
      <program-requirement
        v-for="requirement in program.requirements"
        :key="requirement.id"
        :requirement="requirement"
        :student-id="studentId"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue'
  import ProgramRequirement from './ProgramCompetency.vue'
  import ProgramProgressBar from './ProgramProgressBar.vue'
  import { useI18n } from 'vue-i18n'

  const { locale } = useI18n()

  const unitSize = 3
  const props = defineProps({
    program: {
      type: Object as PropType<Program>,
      required: true,
    },

    studentId: {
      type: String,
      required: true,
    },
  })

  const amountDone = computed(() => {
    return props.program.requirements.reduce(
      (a: number, e: ProgramRequirement) => {
        return a + (e.amountDone || 0)
      },
      0,
    )
  })

  const amountRemaining = computed(() => {
    return props.program.amount - amountDone.value
  })
</script>
