<template>
  <h3 class="p-4 md:px-14 md:pt-14 md:mb-4 md:text-xl">Assessmentmatrix</h3>

  <div class="overflow-x-auto px-4 pb-5 w-full md:px-14 whitespace-nowrap">
    <table
      class="table-fixed min-w-full text-sm bg-white border border-gray-200 inline-table"
    >
      <session-table-headers :session="session" :competencies="competencies" />

      <session-table-rows
        :session="session"
        :competencies="competencies"
        @create:result="emit('create:result', $event)"
        @delete:result="emit('delete:result', $event)"
        @edit:student="emit('edit:student', $event)"
      />

      <session-table-footer :competencies="competencies" :session="session" />
    </table>
  </div>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import SessionTableHeaders from './SessionTableHeaders.vue'
  import SessionTableRows from './SessionTableRows.vue'
  import SessionTableFooter from './SessionTableFooter.vue'

  defineProps({
    session: {
      type: Object as PropType<Session>,
      required: true,
    },

    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },
  })

  const emit = defineEmits(['create:result', 'delete:result', 'edit:student'])
</script>
