<template>
  <div v-if="session">
    <top-header>
      <template #left>
        <icon-button
          title="Terug"
          :icon="ArrowLeftIcon"
          :to="{ name: 'session', params: { sessionId: data.session.id } }"
        />
      </template>

      <template #right>
        <user-name :user="me" />
      </template>
    </top-header>

    <session-form
      class="p-4 md:pt-20 md:min-h-screen md:bg-gray-100"
      :me="me"
      :session="session"
      @save:session="updateSession"
    />
  </div>
</template>

<script lang="ts" setup>
  import { PropType, ref, watch } from 'vue'
  import { useMutation, useQuery } from 'villus'
  import { useRouter, useRoute } from 'vue-router'

  import SessionForm from '../components/SessionForm.vue'

  import query from '../graphql/Session.graphql'
  import UpdateSession from '../graphql/UpdateSession.graphql'

  import { ArrowLeftIcon } from '@heroicons/vue/24/solid'
  import IconButton from '../components/IconButton.vue'
  import TopHeader from '../components/TopHeader.vue'
  import UserName from '../components/UserName.vue'

  defineProps({
    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const router = useRouter()
  const emit = defineEmits(['set:flash'])
  const session = ref<NewSession | null>(null)

  const route = useRoute()
  const variables = { id: route.params.sessionId }
  const { data, isDone } = useQuery({ query, variables })

  watch(isDone, (bool) => {
    if (bool) {
      session.value = {
        context: data.value.session.context,
        deadlineAt: data.value.session.deadlineAt,
        id: data.value.session.id,
        includeInExport: data.value.session.includeInExport,
        kind: data.value.session.kind,
        startingAt: data.value.session.startingAt,
        students: data.value.session.students,
        teachers: data.value.session.teachers,
        title: data.value.session.title,
      }
    }
  })

  const { execute } = useMutation(UpdateSession)
  const updateSession = (session: NewSession) => {
    execute(session).then(({ error, data }) => {
      if (error) {
        emit('set:flash', { kind: 'error', msg: error.message })
      } else {
        emit('set:flash', { kind: 'notice', msg: 'Assessment bijgewerkt' })
        router.push({
          name: 'session',
          params: { sessionId: data.updateSession.id },
        })
      }
    })
  }
</script>
