<template>
  <div>
    <top-header>
      <template #left>
        <icon-button
          :title="$t('system.quit')"
          :icon="ArrowLeftIcon"
          :to="{ name: 'sessions' }"
        />
      </template>

      <template #right>
        <user-name :user="me" />
      </template>
    </top-header>

    <teacher-session
      v-if="me.kind === 'teacher'"
      :competencies="competencies"
      :me="me"
      @set:flash="emit('set:flash', $event)"
    />

    <student-session
      v-else
      :competencies="competencies"
      :me="me"
      @set:flash="emit('set:flash', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'

  import { ArrowLeftIcon } from '@heroicons/vue/24/solid'
  import IconButton from '../components/IconButton.vue'

  import UserName from '../components/UserName.vue'
  import TopHeader from '../components/TopHeader.vue'
  import TeacherSession from '../components/TeacherSession.vue'
  import StudentSession from '../components/StudentSession.vue'

  defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const emit = defineEmits(['set:flash'])
</script>
