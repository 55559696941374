<template>
  <ul>
    <li class="done" v-for="_ in done" />
    <li v-for="_ in remaining" />
  </ul>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  const props = defineProps({
    amountDone: {
      type: Number,
      required: true,
    },
    amountRemaining: {
      type: Number,
      required: true,
    },
  })

  const done = computed(() => props.amountDone < 0 ? 0 : props.amountDone)
  const remaining = computed(() => props.amountRemaining < 0 ? 0 : props.amountRemaining)
</script>

<style lang="postcss" scoped>
  li {
    @apply inline-block w-2 h-2 mr-1 bg-gray-300;
  }

  li.done {
    @apply bg-black;
  }
</style>
