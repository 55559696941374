. timeRemaining
<template>
  <p
    class="flex items-center py-2 px-2 mt-4 text-sm font-bold text-white bg-red-500 rounded-md"
  >
    <bell-icon class="mr-2 w-4 h-4" />

    <span v-if="secondsRemaining > 0">
      {{ $t('sessions.deadline.youHave') }}

      <span class="remaining-elements">
        <span v-if="showDays">
          {{ timeRemaining.days }}
          <template v-if="timeRemaining.days == 1">{{
            $t('sessions.deadline.day')
          }}</template>
          <template v-else>{{ $t('sessions.deadline.days') }}</template>
        </span>

        <span v-if="showHours">
          {{ timeRemaining.hours }}
          <template v-if="timeRemaining.hours == 1">{{
            $t('sessions.deadline.hour')
          }}</template>
          <template v-else>{{ $t('sessions.deadline.hours') }}</template>
        </span>

        <span v-if="showMinutes">
          {{ timeRemaining.minutes }}
          <template v-if="timeRemaining.minutes == 1">{{
            $t('sessions.deadline.minute')
          }}</template>
          <template v-else>{{ $t('sessions.deadline.minutes') }}</template>
        </span>

        <span v-if="showSeconds">
          {{ timeRemaining.seconds }}
          <template v-if="timeRemaining.seconds == 1">{{
            $t('sessions.deadline.second')
          }}</template>
          <template v-else>{{ $t('sessions.deadline.seconds') }}</template>
        </span>
      </span>
    </span>

    <span v-else>{{ $t('sessions.deadline.expired') }}</span>
  </p>
</template>

<script lang="ts" setup>
  import { PropType, ref, computed, watch, onUnmounted } from 'vue'
  import { BellIcon } from '@heroicons/vue/24/solid'

  import { utcToZonedTime } from 'date-fns-tz'

  const props = defineProps({
    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const emit = defineEmits(['is:overdue'])

  const now = ref(new Date())
  const dateInterval = setInterval(() => (now.value = new Date()))
  onUnmounted(() => clearInterval(dateInterval))

  const secondsRemaining = computed(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const deadline = new Date(props.session.deadlineAt + 'Z')
    const deadlineLocal = utcToZonedTime(deadline, tz)
    return (deadlineLocal.getTime() - now.value.getTime()) / 1000
  })

  watch(secondsRemaining, (seconds) => {
    emit('is:overdue', seconds <= 0)
  })

  const timeRemaining = computed(() => {
    const seconds = secondsRemaining.value

    return {
      days: Math.floor(seconds / 86400),
      hours: Math.floor(((seconds / 86400) % 1) * 24),
      minutes: Math.floor(((seconds / 3600) % 1) * 60),
      seconds: Math.round(((seconds / 60) % 1) * 60),
    }
  })

  const showDays = computed(() => {
    const tRem = timeRemaining.value
    return tRem.days > 0
  })

  const showHours = computed(() => {
    const tRem = timeRemaining.value
    return tRem.hours && tRem.hours > 0
  })

  const showMinutes = computed(() => {
    const tRem = timeRemaining.value
    return tRem.minutes && tRem.days < 1
  })

  const showSeconds = computed(() => {
    const tRem = timeRemaining.value
    return tRem.seconds && tRem.days < 1 && tRem.hours < 1
  })
</script>

<style scoped>
  .remaining-elements span:not(:first-child)::before {
    content: ', ';
  }

  .remaining-elements span:last-child::before {
    content: ' en ';
  }

  .remaining-elements span:last-child::after {
    content: '.';
  }
</style>
