<template>
  <div>
    <home-sidebar :competencies="competencies" :me="me" :tenant="tenant" />

    <router-view
      :default-groups="defaultGroups"
      :groups="groups"
      :me="me"
      @add:group="emit('add:group', $event)"
      @add-to:group="emit('add-to:group', $event)"
      @remove-from:group="emit('remove-from:group', $event)"
      @set:flash="emit('set:flash', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
  import HomeSidebar from '../components/HomeSidebar.vue'
  import { PropType } from 'vue'
  import { useRouter } from 'vue-router'

  const props = defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },

    defaultGroups: {
      type: Array as PropType<Group[]>,
      required: true,
    },

    groups: {
      required: true,
      type: Array as PropType<Group[]>,
    },

    me: {
      required: true,
      type: Object as PropType<User>,
    },

    tenant: {
      required: true,
      type: Object as PropType<Tenant>,
    },
  })

  const router = useRouter()
  const isHome = router.currentRoute.value.name === 'home'

  if (isHome) {
    if (props.me.kind === 'student') {
      router.replace({ name: 'student', params: { studentId: props.me.id } })
    } else {
      router.replace({ name: 'sessions' })
    }
  }

  const emit = defineEmits([
    'add:group',
    'add-to:group',
    'remove-from:group',
    'set:flash',
  ])
</script>
