<template>
  <div class="mt-6 first:mt-0">
    <session-goal-header :goal="post.goal" :show-name="false" :state="state" />

    <dl class="my-4 text-xs text-gray-500">
      <dt class="font-bold">Assessment datum</dt>
      <dd class="mb-2">
        {{ formattedTimestamp(post.goal.session.startingAt) }}
      </dd>
      <template v-if="post.goal.session.context">
        <dt class="font-bold">Context</dt>
        <dd class="mb-2">{{ post.goal.session.context }}</dd>
      </template>
      <dt class="font-bold">
        Assessor<template v-if="post.goal.session.teachers.length > 1"
          >en</template
        >
      </dt>
      <dd>
        {{ post.goal.session.teachers.map((t: User) => t.name).join(', ') }}
      </dd>
    </dl>

    <h3 class="mb-2 text-xl">Leerdoelen</h3>
    <div v-html="post.goal.description" />

    <h3 class="mt-4 mb-2 text-xl">Feedback</h3>
    <div v-html="post.body" />
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue'
  import { formattedTimestamp, goalState } from '../utils.js'

  import SessionGoalHeader from './SessionGoalHeader.vue'

  const props = defineProps({
    post: {
      type: Object as PropType<Post>,
      required: true,
    },
  })

  const state = computed(() => goalState(props.post.goal))
</script>
