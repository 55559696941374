<template>
  <div id="editor" class="mb-1">
    <editor-content :editor="editor" />
  </div>

  <div
    v-if="enabled"
    ref="toolbar"
    class="overflow-hidden p-1 space-x-1 border-t border-gray-300"
    :class="{ hidden: !current }"
  >
    <btn @click.prevent="editor?.chain().focus().toggleBold().run()">B</btn>
    <btn @click.prevent="editor?.chain().focus().toggleItalic().run()">I</btn>
    <btn @click.prevent="editor?.chain().focus().toggleBulletList().run()"
      >&bullet;-</btn
    >
  </div>
</template>

<script setup lang="ts">
  import { ref, onBeforeUnmount, watch } from 'vue'
  import { useEditor, EditorContent } from '@tiptap/vue-3'
  import StarterKit from '@tiptap/starter-kit'
  import Btn from './EditorButton.vue'

  const props = defineProps({
    current: {
      type: Boolean,
      default: () => false,
    },

    enabled: {
      type: Boolean,
      default: () => false,
    },

    body: {
      type: String,
      default: () => '',
    },
  })

  const emit = defineEmits(['set:current', 'save:post'])
  const blurTimeout = ref(0)
  const saveTimeout = ref(0)

  const editor = useEditor({
    content: props.body,
    extensions: [StarterKit],

    onFocus() {
      clearTimeout(blurTimeout.value)
      emit('set:current', true)
    },

    onBlur() {
      blurTimeout.value = setTimeout(() => {
        emit('set:current', false)
      }, 200)
    },

    onUpdate({ editor }) {
      if (editor.isEditable) {
        clearTimeout(saveTimeout.value)

        saveTimeout.value = setTimeout(() => {
          const body = editor.getHTML()
          emit('save:post', { body })
        }, 500)
      }
    },
  })

  onBeforeUnmount(() => {
    editor.value?.destroy()
  })

  watch(props, ({ enabled }) => {
    editor.value?.setEditable(enabled)
  })

  watch(props, ({ body: newBody }) => {
    if (!props.current) {
      editor.value?.commands.setContent(newBody)
    }
  })
</script>

<style lang="postcss" scoped>
  #editor:deep(.ProseMirror) {
    @apply outline-none py-3 px-4;
  }

  #editor:deep(ul) {
    @apply list-disc pl-8;
  }

  #editor:deep(p) {
    @apply mb-2 last:mb-0;
  }
</style>
