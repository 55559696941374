<template>
  <tbody>
    <session-table-row
      v-for="(row, idx) in rows"
      :key="`table-row-${idx}`"
      :row="row"
      :session="session"
      @create:result="emit('create:result', $event)"
      @delete:result="emit('delete:result', $event)"
      @edit:student="emit('edit:student', $event)"
    />
  </tbody>
</template>

<script lang="ts" setup>
  import { computed, PropType } from 'vue'
  import SessionTableRow from './SessionTableRow.vue'
  import { uniqObjValues } from '../utils.js'

  const props = defineProps({
    session: {
      type: Object as PropType<Session>,
      required: true,
    },

    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },
  })

  const emit = defineEmits(['create:result', 'delete:result', 'edit:student'])

  const rows = computed<SessionTableRowData[]>(() => {
    return props.session.assessees.map((assessee) => {
      const goals = props.competencies.map((competency) => {
        const competencyGoals = props.session.goals.filter(
          (g: Goal) => g.assessee.id === assessee.id
        )

        const levels = uniqObjValues(competencyGoals, 'level')

        return levels.map((level) => {
          const goals = props.session.goals.filter((goal) => {
            return (
              goal.level === level &&
              goal.competency.id === competency.id &&
              goal.assessee.id === assessee.id
            )
          })

          return goals.length ? goals[0] : null
        })
      })

      return { student: assessee.student, goals }
    })
  })
</script>
