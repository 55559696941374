const messages = {
  en: {
    actions: {
      back: 'Back',
      create: 'create {entity}'
    },

    entities: {
      competency: {
        singular: 'competence',
        plural: 'competences'
      },

      goal: {
        singular: 'learning goal',
        plural: 'learning goals',
        amount: 'amount',
        level: 'level',
        feedback: 'Feedback'
      },

      session: {
        singular: 'assessment',
        plural: 'assessments',
        kinds: {
          integral: 'Integral @:entities.session.singular',
          practice: 'Practice @:entities.session.singular',
          individual: 'Individual @:entities.session.singular',
          internship: 'Internship @:entities.session.singular',
          graduation: 'Graduation @:entities.session.singular'
        },
        startingAt: 'Date',
        deadlineAt: 'Deadline',
        title: 'Title',
        kind: 'Kind',
        context: 'Context',
        teachers: {
          plural: 'Assessors',
          singular: 'Assessor'
        },
        feedback: 'Feedback'
      },

      student: {
        singular: 'student',
        plural: 'students'
      }
    },

    features: {
      admin: 'Admin',
      sessions: '@.capitalize:entities.session.plural',
      progress: 'Progress',
      otherApps: 'Other applications'
    },

    goals: {
      addGoal: '@.capitalize:entities.goal.singular add',
      removeGoal: '@.capitalize:entities.goal.singular remove'
    },

    groups: {
      comingSemester: 'Minor: <strong>{title}</strong>',
      mentor: 'Mentor',
      graduation: 'Graduation',
      internship: 'Internship'
    },

    posts: {
      empty: 'No feedback.',
      none: 'There is no feedback for this competence yet...'
    },

    programs: {
      freeSpace: 'Choice',
      amountDone: '{amount} EC achieved',
      amountRemaining: '{amount} EC remaining'
    },

    session: {
      logout: 'logout',
      total: 'Total'
    },

    sessions: {
      deadline: {
        and: 'and',
        expired: 'The deadline has expired',
        youHave: 'You have remaining',
        day: 'day',
        days: 'days',
        hour: 'hour',
        hours: 'hours',
        minutes: 'minutes',
        minute: 'minute',
        seconds: 'seconds',
        second: 'second'
      },
      completed: 'Completed assessments',
      goTo: 'Go to assessment',
      showAll: 'Show all',
      new: {
        details: 'Details',
        summary: 'Summary',
        title: 'New assessment'
      },
      finalize: {
        mergeFirst: 'You can finalize, only when<br> all competencies have been graded.',
        start: 'Start merging',
        finalize: 'Finalize',
        pending: 'Assessment is being merged.'
      },
      templates: {
        select: 'Select a template'
      }
    },

    studentSession: {
      submitGoals: 'Submit @:entities.goal.plural',
      pendingResults: 'Not yet completed',
      pendingExplanation: 'Still waiting on feedback'
    },

    system: {
      cancel: 'Cancel',
      loading: 'Loading...',
      select: 'Select {title}',
      quit: 'Close',
      options: 'Options',
      save: 'Save',
      add: 'Add'
    },

    unauthorized: {
      login: 'Log in',
      requiresLogin: 'Authorization is required to use this application.',
      avgNotice: '{appName} doesn\'t store your personal details, but in written feedback they might occur. These details are saved and processed inside the European Union.'
    }
  },

  nl: {
    actions: {
      back: 'Terug',
      create: '{entity} aanmaken'
    },

    entities: {
      competency: {
        singular: 'competentie',
        plural: 'competenties'
      },

      goal: {
        singular: 'leerdoel',
        plural: 'leerdoelen',
        amount: 'aantal',
        level: 'niveau',
        feedback: 'Feedback'
      },

      session: {
        singular: 'assessment',
        plural: 'assessments',
        kinds: {
          integral: 'Integraal @:entities.session.singular',
          practice: 'Proef@:entities.session.singular',
          individual: 'Individueel @:entities.session.singular',
          internship: 'Stage-@:entities.session.singular',
          graduation: 'Afstudeer@:entities.session.singular'
        },
        startingAt: 'Datum',
        deadlineAt: 'Deadline',
        title: 'Titel',
        kind: 'Soort',
        context: 'Context',
        teachers: {
          plural: 'Assessoren',
          singular: 'Assessor'
        },
        feedback: 'Feedback'
      },

      student: {
        singular: 'student',
        plural: 'studenten'
      },
      
      templates: {
        select: 'Select a template'
      }
    },

    features: {
      admin: 'Beheer',
      sessions: '@.capitalize:entities.session.plural',
      progress: 'Voortgang',
      otherApps: 'Andere applicaties'
    },

    goals: {
      addGoal: '@.capitalize:entities.goal.singular toevoegen',
      removeGoal: '@.capitalize:entities.goal.singular verwijderen'
    },

    groups: {
      comingSemester: 'Minor: <strong>{title}</strong>',
      mentor: 'Mentor',
      graduation: 'Afstuderen',
      internship: 'Stage'
    },

    posts: {
      empty: 'Geen feedback.',
      none: 'Voor deze competentie is nog geen feedback geschreven.'
    },

    programs: {
      freeSpace: 'Vrije ruimte',
      amountDone: '{amount} EC behaald',
      amountRemaining: 'nog {amount} EC'
    },

    session: {
      logout: 'Uitloggen',
      total: 'Totaal'
    },

    sessions: {
      deadline: {
        and: 'en',
        expired: 'De deadline is verlopen',
        youHave: 'Je hebt nog',
        day: 'dag',
        days: 'dagen',
        hour: 'uur',
        hours: 'uren',
        minutes: 'minuten',
        minute: 'minuut',
        seconds: 'seconden',
        second: 'seconde'
      },
      completed: 'Afgeronde assessments',
      goTo: 'Ga naar assessment',
      showAll: 'Toon alles',
      new: {
        details: 'Details',
        summary: 'Overzicht',
        title: 'Nieuw assessment'
      },
      finalize: {
        mergeFirst: 'Je kunt pas samenvoegen als<br>alle competenties een beoordeling hebben.',
        start: 'Start samenvoegen',
        finalize: 'Definitief maken',
        pending: 'Assessment wordt samengevoegd.'
      },
      templates: {
        select: 'Selecteer een template'
      }
    },

    studentSession: {
      submitGoals: 'Aanleveren @:entities.goal.plural',
      pendingResults: 'Nog niet afgerond',
      pendingExplanation: 'Voor dit assessment is (nog) geen feedback beschikbaar.'
    },

    system: {
      cancel: 'Annuleren',
      loading: 'Bezig met laden...',
      select: 'Selecteer {title}',
      quit: 'Afsluiten',
      options: 'Opties',
      save: 'Opslaan',
      add: 'Toevoegen'
    },

    unauthorized: {
      login: 'Inloggen',
      requiresLogin: 'Om deze applicatie te kunnen gebruiken moet je eerst inloggen.',
      avgNotice: '{appName} slaat je persoonsgegevens niet op, maar in geschreven feedback kan dat wel voorkomen. Deze gegevens worden opgeslagen en verwerkt binnen de Europese Unie.'
    }
  }
}

export default messages
