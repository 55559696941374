<template>
  <card link-title="Zet open" @link-clicked="submitForm">
    <template #title>Assessment open zetten</template>

    <form class="flex flex-col space-y-2" @submit.prevent="unfinalizeSession">
      <label>
        ID van het assessment
        <input
          ref="idInput"
          v-model="sessionId"
          required
          pattern="^[a-zA-Z0-9-]{36}$"
          placeholder="00000000-0000-0000-0000-000000000000"
        />
      </label>

      <label class="flex items-center gap-2">
        <input v-model="removeFinalPosts" type="checkbox" class="!w-auto" />
        <span>Verwijder samengevoegde berichten</span>
      </label>

      <button ref="submitButton" class="hidden">Submit</button>
    </form>
  </card>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'

  import Card from './Card.vue'

  import { useMutation } from 'villus'
  import UnfinalizeSession from '../graphql/UnfinalizeSession.graphql'

  const { execute } = useMutation(UnfinalizeSession)
  const sessionId = ref('')
  const removeFinalPosts = ref(false)

  const state = computed(() =>
    removeFinalPosts.value ? 'active' : 'finalizing'
  )

  const idInput = ref<HTMLInputElement | null>(null)
  const submitButton = ref<HTMLButtonElement | null>(null)

  const emit = defineEmits(['set:flash'])

  const unfinalizeSession = () => {
    execute({ id: sessionId.value, state: state.value }).then(
      ({ data, error }) => {
        if (error) {
          emit('set:flash', {
            kind: 'alert',
            msg: error.message,
          })

          idInput.value?.focus()
        } else {
          emit('set:flash', {
            kind: 'info',
            msg: `${data.unfinalizeSession.title} open gezet.`,
          })

          sessionId.value = ''
        }
      }
    )
  }

  const submitForm = () => submitButton.value?.click()
</script>

<style lang="postcss" scoped>
  label input {
    @apply block px-2 pt-1 bg-gray-100 rounded-md w-full;
  }
</style>
