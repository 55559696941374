<template>
  <section
    v-for="obj of goalsByCompetency"
    :key="`competency-${obj.competency.id}`"
  >
    <h3 :id="`competency-${obj.competency.id}`" class="mb-4 text-lg md:text-xl">
      {{ obj.competency.title }}
    </h3>

    <div
      v-for="goal in obj.goals"
      :id="`goal-${goal.id}`"
      :key="`goal-${goal.id}`"
      class="mt-4 first:mt-0"
    >
      <session-goal
        :goal="goal"
        :me="me"
        :post="goalPost(goal)"
        :session="session"
        @save:post="emit('save:post', $event)"
        @add:attachment="emit('add:attachment', $event)"
        @create:attachment="emit('create:attachment', $event)"
        @delete:attachment="emit('delete:attachment', $event)"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue'
  import SessionGoal from './SessionGoal.vue'

  import { mapGoalsByCompetency } from '../utils.js'

  const props = defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const emit = defineEmits([
    'add:attachment',
    'create:attachment',
    'delete:attachment',
    'save:post',
  ])

  const goalsByCompetency = computed(() => {
    return mapGoalsByCompetency(props.competencies, props.session.goals)
  })

  const goalPost = (goal: Goal) => {
    return props.session.posts.find((p) => p.goal?.id === goal?.id)
  }
</script>
