<template>
  <dl class="grid grid-cols-3 text-sm">
    <session-details-item
      v-if="showField('startingAt')"
      :title="$t('entities.session.startingAt')"
      :value="formattedTimestamp(session.startingAt)"
    />

    <session-details-item
      v-if="showField('deadlineAt')"
      :title="$t('entities.session.deadlineAt')"
      :value="formattedTimestamp(session.deadlineAt)"
    />

    <session-details-item
      v-if="showField('title')"
      :title="$t('entities.session.title')"
      :value="session.title"
    />

    <session-details-item
      v-if="showField('kind')"
      :title="$t('entities.session.kind')"
      :value="$t(`entities.session.kinds.${session.kind}`)"
    />

    <session-details-item
      v-if="showField('context')"
      :title="$t('entities.session.context')"
      :value="session.context"
    />
  </dl>
</template>

<script lang="ts" setup>
  import { formattedTimestamp } from '../utils.js'
  import { PropType } from 'vue'

  import SessionDetailsItem from './SessionDetailsItem.vue'

  type SessionField = 'startingAt' | 'deadlineAt' | 'title' | 'kind' | 'context'

  const props = defineProps({
    fields: {
      type: Array as PropType<SessionField[]>,
      default: () => ['startingAt', 'deadlineAt'],
    },

    session: {
      type: Object as PropType<Session | NewSession>,
      required: true,
    },
  })

  const showField: (field: SessionField) => boolean = (field) => {
    return props.fields.includes(field) && props.session[field]
  }
</script>
