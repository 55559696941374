<template>
  <div class="relative">
    <input
      v-model="searchQuery"
      placeholder="Zoek student…"
      autofocus
      class="block py-1 px-2 w-full bg-white rounded-none border border-gray-300 appearance-none"
    />

    <div
      v-if="searchDone && searchResults.students.length"
      class="overflow-auto absolute w-full p-2 max-h-60 bg-white rounded-md border-t shadow-md border-t-gray-100"
    >
      <user-search-results
        :users="searchResults.students"
        @select:user="selectUser"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, computed } from 'vue'
  import { useQuery } from 'villus'

  import SearchQuery from '../graphql/Students.graphql'
  import UserSearchResults from '../components/UserSearchResults.vue'

  const emit = defineEmits(['select:user'])

  const searchQuery = ref('')
  const searchVariables = computed(() => {
    return { query: searchQuery.value }
  })

  const selectUser = (user: User) => {
    searchQuery.value = ''
    emit('select:user', user)
  }

  const { data: searchResults, isDone: searchDone } = useQuery({
    query: SearchQuery,
    variables: searchVariables,
    fetchOnMount: false,
  })
</script>
