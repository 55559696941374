import { createRouter, createWebHistory } from 'vue-router'

import Admin from '../views/AdminView.vue'
import Groups from '../views/Groups.vue'
import Home from '../views/Home.vue'
import EditSession from '../views/EditSession.vue'
import NewSession from '../views/NewSession.vue'
import Sessions from '../views/Sessions.vue'
import Session from '../views/Session.vue'
import SessionGoal from '../views/SessionGoal.vue'
import Students from '../views/Students.vue'
import Student from '../views/Student.vue'
import StudentCompetency from '../views/StudentCompetency.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      { path: '/sessions', name: 'sessions', component: Sessions },
      {
        path: '/students',
        name: 'students',
        component: Students,
        redirect: '/students/groups',
        children: [
          { path: '/students/groups', name: 'groups', component: Groups },
          {
            path: '/students/:studentId',
            name: 'student',
            component: Student,
            children: [
              {
                path: '/students/:studentId/competency/:competencyId',
                name: 'student_competency',
                component: StudentCompetency,
              },
            ],
          },
        ],
      },
      {
        path: '/beheer',
        name: 'admin',
        component: Admin,
      },
    ],
  },

  {
    path: '/sessions/new',
    name: 'new_session',
    component: NewSession,
  },

  {
    path: '/sessions/:sessionId',
    name: 'session',
    component: Session,
    children: [
      {
        path: '/sessions/:sessionId/goals/:goalId',
        name: 'session_goal',
        component: SessionGoal,
      },
    ],
  },

  {
    path: '/sessions/:sessionId/edit',
    name: 'edit_session',
    component: EditSession,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
