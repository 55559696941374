<template>
  <card :link-route="route" :link-title="$t('sessions.goTo')">
    <template #title>{{ session.title }}</template>
    <template #subtitle>{{
      $t(`entities.session.kinds.${session.kind}`)
    }}</template>

    <session-details :session="session" />

    <session-deadline-banner
      v-if="session.deadlineAt && !session.finalized && me.kind !== 'teacher'"
      :session="session"
    />
  </card>
</template>

<script lang="ts" setup>
  import { computed, PropType } from 'vue'

  import Card from './Card.vue'
  import SessionDetails from './SessionDetails.vue'
  import SessionDeadlineBanner from './SessionDeadlineBanner.vue'

  const props = defineProps({
    me: {
      type: Object as PropType<User>,
      required: true,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const route = computed(() => {
    return { name: 'session', params: { sessionId: props.session.id } }
  })
</script>
