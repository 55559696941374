<template>
  <section
    class="md:min-h-screen md:bg-gray-100"
    :class="{ 'md:ml-60': showSidebar }"
  >
    <session-header
      :session="session"
      :show-sidebar="showSidebar"
      @finalize:session="emit('finalize:session')"
      @start_finalizing:session="emit('start_finalizing:session')"
      @delete:session="emit('delete:session', $event)"
      @show:sidebar="emit('show:sidebar', $event)"
    />

    <div class="max-w-5xl">
      <session-table
        :competencies="competencies"
        :session="session"
        @create:result="emit('create:result', $event)"
        @delete:result="emit('delete:result', $event)"
        @edit:student="editableStudent = $event"
      />

      <session-student
        v-if="editableStudent"
        :me="me"
        :student="editableStudent"
        :session="session"
        :competencies="competencies"
        @set:flash="emit('set:flash', $event)"
        @dismiss="editableStudent = null"
        @set:goals="emit('set:goals', $event)"
      />

      <div class="p-4 space-y-6 max-w-5xl md:px-14">
        <session-goal
          :me="me"
          :post="generalPost"
          :session="session"
          @save:post="emit('save:post', $event)"
        />

        <template v-if="session.goals.length">
          <session-goals
            :session="session"
            :competencies="competencies"
            :me="me"
            @save:post="emit('save:post', $event)"
            @add:attachment="emit('add:attachment', $event)"
            @create:attachment="emit('create:attachment', $event)"
            @delete:attachment="emit('delete:attachment', $event)"
          />
        </template>

        <div v-else class="p-4 md:p-14">
          <h2 class="mb-4 text-2xl">Nog geen leerdoelen toegevoegd</h2>
          <p>
            De uitgenodigde studenten hebben nog geen leerdoelen toegevoegd.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
  import { computed, ref, PropType } from 'vue'

  import SessionHeader from './SessionHeader.vue'
  import SessionTable from './SessionTable.vue'
  import SessionGoals from './SessionGoals.vue'
  import SessionGoal from './SessionGoal.vue'

  import SessionStudent from './SessionStudent.vue'

  const emit = defineEmits([
    'add:attachment',
    'create:attachment',
    'delete:attachment',
    'delete:session',
    'create:result',
    'delete:result',
    'finalize:session',
    'save:post',
    'set:flash',
    'set:goals',
    'start_finalizing:session',
    'show:sidebar',
  ])

  const props = defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },

    showSidebar: {
      type: Boolean,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const editableStudent = ref<null | User>(null)
  const generalPost = computed(() => {
    return props.session.posts.find((p) => p.goal === null)
  })
</script>
