<template>
  <thead class="text-xs text-left">
    <tr>
      <session-table-headers-col v-for="header in headers" :header="header" />
    </tr>
  </thead>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import SessionTableHeadersCol from './SessionTableHeadersCol.vue'
  import { useI18n } from 'vue-i18n'
  const { locale, t } = useI18n()

  const props = defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },
  })

  const competencyTitles = props.competencies.map((c) => {
    return { title: locale.value === 'en' ? c.titleEn : c.title, width: 120 }
  })

  const headers = [
    { title: 'Student', width: 175 },
    competencyTitles,
    { title: t('session.total'), colspan: 2, width: 100 },
  ].flat()
</script>
