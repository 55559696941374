<template>
  <home-content>
    <template #title>Beheer</template>

    <section class="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3 max-w-4xl">
      <admin-export-results />
      <admin-unfinalize-session @set:flash="emit('set:flash', $event)" />
    </section>
  </home-content>
</template>

<script lang="ts" setup>
  import HomeContent from '../components/HomeContent.vue'
  import AdminExportResults from '../components/AdminExportResults.vue'
  import AdminUnfinalizeSession from '../components/AdminUnfinalizeSession.vue'

  import { PropType } from 'vue'

  const emit = defineEmits(['set:flash'])

  defineProps({
    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })
</script>
