<template>
  <div v-if="isDone" class="mt-6">
    <h2 class="mb-4 text-xl font-bold flex items-center">
      {{ data.studentResults.student.name }}

      <a v-if="isDev && me.kind === 'teacher'" class="text-sm font-normal ml-auto" :href="loginUrl">
        Inloggen
      </a>

      <a
        v-else-if="data.studentResults.student.studentId"
        class="bg-black text-neutral-100 py-1 px-2 rounded-lg text-sm font-normal ml-auto flex gap-2"
        :href="`https://yos.nhlstenden.com/students/${data.studentResults.student.studentId}/progress`"
      >
        <span>Your Own Space</span>
        <arrow-top-right-on-square-icon class="w-4" />
      </a>
    </h2>

    <ul class="labels mb-2 -mx-4 px-4 flex gap-2 overflow-auto">
      <li v-if="data.studentResults.student.studentId && me.kind === 'teacher'">
        Studentnummer:
        <strong>{{ data.studentResults.student.studentId }}</strong>
      </li>

      <li
        v-for="group in userGroups"
        :key="group.id"
        v-html="$t('groups.comingSemester', { title: group.title })"
      ></li>

      <li v-for="group in teacherGroups" :key="group.id">
        {{ $t('groups.mentor')
        }}<template v-if="teacherGroups.length > 0">
          <template v-if="group.slug === 'graduation'">
            ({{ $t('groups.graduation') }})</template
          >
          <template v-if="group.slug === 'intern'">
            ({{ $t('groups.internship') }})</template
          ></template
        >:
        <strong>{{ (group.teachers || [])[0]?.name }}</strong>
      </li>
    </ul>

    <template v-if="route.name === 'student'">
      <ul
        class="border-b border-b-gray-300 mb-4 overflow-auto flex gap-4 -mx-4 px-4 md:mx-0 md:px-0"
      >
        <li
          v-for="program in programs"
          :key="program.id"
          :class="{
            'border-b-sky-500 border-b-2 cursor-default':
              isCurrentProgram(program),
          }"
          class="py-2 cursor-pointer float-left"
          @click="currentProgram = program"
        >
          {{ locale === 'en' ? program.titleEn : program.title }}
        </li>
      </ul>

      <program
        v-if="currentProgram"
        :program="currentProgram"
        :student-id="studentId"
      />
    </template>

    <router-view :student="data.studentResults.student" v-else />
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref, watch, PropType, reactive } from 'vue'
  import { useQuery } from 'villus'
  import { useRoute } from 'vue-router'
  import { useHead } from "@vueuse/head";

  import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'

  import query from '../graphql/StudentProgress.graphql'
  import Program from '../components/Program.vue'
  import { useI18n } from 'vue-i18n'

  const { locale } = useI18n()

  defineProps({
    groups: {
      type: Array as PropType<Group[]>,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const currentProgram = ref(null)
  const route = useRoute()

  const studentId = computed(() => route.params.studentId as string)
  const variables = reactive({ studentId: studentId })

  const isDev = import.meta.env.DEV
  const loginUrl =
    import.meta.env.VITE_APP_DEV_LOGIN_URL_STUDENT + '/' + studentId.value

  const { data, isDone } = useQuery({ query, variables })

  const programs = computed(() => {
    if (isDone.value) {
      let goals: Goal[] = [...data.value.studentResults.goals]

      const allPrograms = data.value.programs.map(
        (program: Program) => program.sessionKind,
      )
      const uniqPrograms = Array.from(new Set(allPrograms))
      const specialPrograms = uniqPrograms.filter((kind) => kind)

      return data.value.programs.map((program: Program) => {
        return {
          id: program.id,
          title: program.title,
          titleEn: program.titleEn,
          amount: program.requirements.reduce(
            (sum, req) => sum + req.amount,
            0,
          ),
          requirements: program.requirements.map((req) => {
            const results: Result[] = []

            goals = goals.filter((goal) => {
              if (
                // Goal has the proper kind for current Program, meaning…
                // Program has no special kind, and kind is not unique to other program.
                ((program.sessionKind === null &&
                  !specialPrograms.includes(goal.session.kind)) ||
                  // Or… program has a special kind, and this goal is of that kind.
                  program.sessionKind === goal.session.kind) &&
                // Goal has a valid result for the current requirement, meaning…
                // It has the same competency as given goal, and the same level.
                ((req.competency &&
                  req.competency.id === goal.competency?.id &&
                  req.level === goal.result?.level) ||
                  // Or… it fits in with the 'free space'-basket, because it has the proper level.
                  (req.competency === null && req.level === goal.result?.level))
              ) {
                results.push(goal.result)
                return false
              }

              return true
            })

            req.results = results

            req.amountDone = results.reduce(
              (sum: number, e: Result) => sum + e.amount,
              0,
            )

            return req
          }),
        }
      })
    } else {
      return []
    }
  })

  const isCurrentProgram = (program: Program) => {
    if (currentProgram.value) {
      const curProgram = currentProgram.value as Program
      return program.id === curProgram.id
    } else {
      return false
    }
  }

  const userGroups = computed(() => {
    const allGroups: Group[] = data.value.studentResults.student.groups
    return allGroups.filter((g) => !g.teachers?.length)
  })

  const teacherGroups = computed(() => {
    const allGroups: Group[] = data.value.studentResults.student.groups
    return allGroups.filter((g) => g.slug)
  })

  watch(programs, (newPrograms) => {
    if (newPrograms.length) {
      currentProgram.value = newPrograms[0]
    }
  })

  useHead({ title: () => data.value ? data.value.studentResults.student.name : null })
</script>

<style lang="postcss" scoped>
  .labels li {
    @apply bg-white text-sm text-gray-800 border-gray-300 border inline-block px-2 py-1 rounded-md whitespace-nowrap;
  }
</style>
