<template>
  <div
    class="fixed z-30 px-2 text-white text-center md:right-0 bottom-2"
    @click="dismiss"
  >
    <p
      class="bg-white/[0.6] text-black text-sm border border-gray-300 max-w-lg py-2 px-3 rounded-lg flex items-center"
    >
      <span class="w-5 mr-1">
        <bell-alert-icon v-if="modelValue.kind === 'alert'" />
        <information-circle-icon v-else />
      </span>

      <span>{{ modelValue.msg }}</span>
    </p>
  </div>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import { InformationCircleIcon, BellAlertIcon } from '@heroicons/vue/20/solid'

  defineProps({
    modelValue: {
      type: Object as PropType<Flash>,
      required: true,
    },
  })

  const emit = defineEmits(['update:modelValue'])

  const dismiss = () => {
    clearTimeout(timeout)
    emit('update:modelValue', null)
  }

  const timeout = setTimeout(dismiss, 3500)
</script>
