<template>
  <div class="flex flex-col p-4 bg-white rounded-md border border-gray-200">
    <h2 class="text-xl">
      <slot name="title" />
    </h2>

    <h3 v-if="slots.subtitle" class="text-sm text-gray-500">
      <slot name="subtitle" />
    </h3>

    <div class="my-4">
      <slot />
    </div>

    <p v-if="linkRoute" class="mt-auto text-right">
      <router-link :to="linkRoute" class="text-sky-500">{{
        linkTitle
      }}</router-link>
    </p>

    <p v-else-if="linkExternal" class="mt-auto text-right">
      <a :href="linkExternal" class="text-sky-500">{{ linkTitle }}</a>
    </p>

    <p v-else-if="linkTitle" class="mt-auto text-right">
      <a class="text-sky-500 cursor-pointer" @click="emit('link-clicked')">{{
        linkTitle
      }}</a>
    </p>
  </div>
</template>

<script lang="ts" setup>
  import { useSlots, PropType } from 'vue'
  import type { RouteLocationRaw } from 'vue-router'
  const slots = useSlots()

  defineProps({
    linkRoute: {
      type: Object as PropType<null | RouteLocationRaw>,
    },

    linkTitle: {
      type: String as PropType<null | string>,
    },

    linkExternal: {
      type: String,
    },
  })

  const emit = defineEmits(['link-clicked'])
</script>
