<template>
  <div>
    <top-header>
      <template #left>
        <icon-button
          :title="$t('system.quit')"
          :icon="ArrowLeftIcon"
          :to="{ name: 'sessions' }"
        />
      </template>

      <template #right>
        <user-name :user="me" />
      </template>
    </top-header>

    <session-form
      class="p-4 md:pt-20 md:min-h-screen md:bg-gray-100"
      :me="me"
      :session="session"
      @save:session="createSession"
    />
  </div>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import { useMutation } from 'villus'
  import { useRouter } from 'vue-router'
  import { useHead } from "@vueuse/head";
  import { useI18n } from 'vue-i18n';

  import query from '../graphql/CreateSession.graphql'
  import SessionForm from '../components/SessionForm.vue'

  import { ArrowLeftIcon } from '@heroicons/vue/24/solid'
  import IconButton from '../components/IconButton.vue'
  import TopHeader from '../components/TopHeader.vue'
  import UserName from '../components/UserName.vue'

  const props = defineProps({
    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const { t } = useI18n()

  const router = useRouter()
  const emit = defineEmits(['set:flash'])

  const session: NewSession = {
    context: '',
    deadlineAt: '',
    includeInExport: true,
    kind: '',
    startingAt: '',
    students: [],
    teachers: [props.me],
    title: '',
  }

  const { execute } = useMutation(query)
  const createSession = (session: NewSession) => {
    execute(session).then(({ error, data }) => {
      if (error) {
        emit('set:flash', { kind: 'error', msg: error.message })
      } else {
        emit('set:flash', { kind: 'notice', msg: 'Assessment aangemaakt' })
        router.push({
          name: 'session',
          params: { sessionId: data.createSession.id },
        })
      }
    })
  }

  useHead({ title: () => t('sessions.new.title') })
</script>
