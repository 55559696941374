<template>
  <home-content>
    <template v-if="me.kind == 'teacher'" #title>Studenten</template>
    <template v-else #title>Voortgang</template>

    <div class="max-w-2xl">
      <student-search v-if="me.kind === 'teacher'" @select:user="selectUser" />

      <router-view
        :default-groups="defaultGroups"
        :groups="groups"
        :me="me"
        @add:group="emit('add:group', $event)"
        @add-to:group="emit('add-to:group', $event)"
        @remove-from:group="emit('remove-from:group', $event)"
      />
    </div>
  </home-content>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import { useRouter } from 'vue-router'

  import HomeContent from '../components/HomeContent.vue'
  import StudentSearch from '../components/StudentSearch.vue'

  const router = useRouter()

  const selectUser = (user: User) => {
    const route = { name: 'student', params: { studentId: user.id } }
    router.push(route)
  }

  defineProps({
    defaultGroups: {
      required: true,
      type: Array as PropType<Group[]>,
    },
    groups: {
      required: true,
      type: Array as PropType<Group[]>,
    },
    me: {
      required: true,
      type: Object as PropType<User>,
    },
  })

  const emit = defineEmits(['add:group', 'add-to:group', 'remove-from:group'])
</script>
