<template>
  <flash v-if="flash" v-model="flash" />

  <router-view
    v-if="isAuthorized"
    :me="data.me"
    :competencies="data.competencies"
    :default-groups="data.defaultGroups"
    :groups="data.groups"
    :tenant="data.tenant"
    @add:group="addGroup"
    @add-to:group="addToGroup"
    @remove-from:group="removeFromGroup"
    @set:flash="flash = $event"
  >
  </router-view>

  <unauthorized v-else-if="isDone" />

  <div v-else class="my-5 flex justify-center pointer-events-none">
    <arrow-path-icon class="w-5 animate-spin" />
  </div>
</template>

<script lang="ts" setup>
  import * as Sentry from '@sentry/vue'
  import { ref, watch, onUnmounted } from 'vue'
  import type { Ref } from 'vue'
  import { useQuery } from 'villus'
  import { useHead } from "@vueuse/head";

  import query from './graphql/Account.graphql'

  import Flash from './components/Flash.vue'
  import Unauthorized from './views/Unauthorized.vue'

  import { ArrowPathIcon } from '@heroicons/vue/24/solid'

  const flash: Ref<Flash | null> = ref(null)
  const isAuthorized = ref(false)
  const { data, error, isDone } = useQuery({ query })

  // Authentication interval.

  const authInterval = ref<number>(0)
  const authIntervalDelay = 120
  const checkLoginStatus = () => {
    const url = import.meta.env.VITE_APP_LOGIN_STATUS_URL
    fetch(url, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ exp }) => {
        if (exp) {
          if (exp < authIntervalDelay) {
            flash.value = {
              kind: 'notice',
              msg: 'Let op: je sessie verloopt binnenkort.',
            }
          }

          isAuthorized.value = true
        } else {
          flash.value = {
            kind: 'alert',
            msg: 'De sessie is verlopen, je wordt uitgelogd.',
          }
          isAuthorized.value = false

          clearInterval(authInterval.value)
        }
      })
      .catch(() => (isAuthorized.value = false))
  }

  onUnmounted(() => clearInterval(authInterval.value))

  watch(isDone, () => {
    if (error.value) {
      flash.value = { kind: 'alert', msg: error.value.message }
      isAuthorized.value = false
    } else {
      const authIntervalDelayMs = authIntervalDelay * 1000
      authInterval.value = setInterval(checkLoginStatus, authIntervalDelayMs)
      flash.value = { kind: 'notice', msg: 'Welkom terug' }

      Sentry.setUser({
        name: data.value.me.name,
        id: data.value.me.id
      });

      checkLoginStatus()
    }
  })

  // Group-callbacks.

  const addGroup = (group: Group) => data.value.groups.push(group)

  const addToGroup = (obj: UserForGroup) => {
    const groups = data.value.groups as Group[]
    const currentGroup = groups.find((g) => g.id == obj.group.id)

    if (currentGroup) {
      currentGroup.students.push(obj.user)
    }
  }

  const removeFromGroup = (obj: UserForGroup) => {
    const groups = data.value.groups as Group[]
    const currentGroup = groups.find((g) => g.id == obj.group.id)

    if (currentGroup) {
      const userIds = currentGroup.students.map((s) => s.id)
      const idx = userIds.indexOf(obj.user.id)
      currentGroup.students.splice(idx, 1)
    }
  }

useHead({
  titleTemplate: (title?: string) => {
    const name = "FFWD";
    return !title ? name : `${title} - ${name}`;
  },
});
</script>

<style lang="postcss">
  body,
  html {
    margin: 0;
  }
</style>
