<template>
  <div class="p-4 md:pt-20 md:min-h-screen md:bg-gray-100">
    <component
      :is="steps[step]"
      :me="me"
      :session="localSession"
      :step="step"
      @save:session="saveSession"
      @set:step="step = $event"
      @update:value="updateValue($event as NewSessionInput)"
    />
  </div>
</template>

<script lang="ts" setup>
  import { PropType, reactive, ref } from 'vue'

  import NewSessionDetails from '../components/NewSessionDetails.vue'
  import NewSessionStudents from '../components/NewSessionStudents.vue'
  import NewSessionTeachers from '../components/NewSessionTeachers.vue'
  import NewSessionSummary from '../components/NewSessionSummary.vue'

  const props = defineProps({
    me: {
      type: Object as PropType<User>,
      required: true,
    },

    session: {
      type: Object as PropType<NewSession>,
      required: true,
    },
  })

  const localSession = reactive(props.session)
  const emit = defineEmits(['set:flash', 'save:session'])

  const step = ref(0)
  const steps = [
    NewSessionDetails,
    NewSessionStudents,
    NewSessionTeachers,
    NewSessionSummary,
  ]

  const updateValue = (input: NewSessionInput) => {
    localSession[input.field] = input.value
  }

  const saveSession = () => {
    const session = localSession

    // Replace users with userIds.
    session.studentIds = session.students?.map((s) => s.remoteId)
    session.teacherIds = session.teachers?.map((s) => s.remoteId)

    emit('save:session', session)
  }
</script>

<style lang="postcss" scoped>
  :deep(label) {
    @apply text-sm;
  }

  :deep(input:not([type='checkbox'])),
  :deep(select) {
    @apply bg-white border border-gray-300 block w-full py-1 px-2 appearance-none rounded-none mt-1;
  }
</style>
