import dayjs from 'dayjs'
import 'dayjs/locale/nl'

dayjs.locale('nl')

function formattedTimestamp(date: Date, format?: string): string {
  const defaultFormat = 'DD MMMM YYYY'
  return dayjs(date).format(format || defaultFormat)
}

function goalState(goal: Goal) {
  const amount = goal.amount
  const result = goal.result

  if (result) {
    if (result.amount === 0) {
      return 'fail'
    } else if (result.amount === null) {
      return 'no-result'
    } else if (result.amount < amount) {
      return 'semi-fail'
    } else {
      return 'success'
    }
  } else {
    return null
  }
}

function mapGoalsByCompetency(competencies: Competency[], goals: Goal[]) {
  return competencies
    .map((competency) => {
      const filteredGoals = goals.filter(
        (g) => g.competency.id === competency.id
      )

      return { competency, goals: filteredGoals }
    })
    .filter(({ goals }) => goals.length > 0)
}

function timestampToISO(ts: string | null): string | null {
  if (ts && ts.length) {
    const dt = new Date(ts)
    return dt.toISOString()
  } else {
    return null
  }
}

function uniqObjValues(arr: Array<Object>, key: string): Array<any> {
  const nonNullValues = arr.filter((x) => x)
  const values = nonNullValues.flatMap((o: Object) => o[key as keyof Object])
  const valueSet = new Set(values)
  const uniqValues = Array.from(valueSet)

  return uniqValues.sort()
}

export {
  formattedTimestamp,
  goalState,
  mapGoalsByCompetency,
  timestampToISO,
  uniqObjValues
}
