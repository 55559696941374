<template>
  <aside
    class="p-4 bg-gray-100 md:fixed md:overflow-auto md:pt-20 md:w-60 md:min-h-screen md:border-r md:border-gray-200 group"
  >
    <h2 class="font-bold">{{ session.title }}</h2>

    <ul class="mt-4" v-for="obj in goalsByCompetency">
      <li>
        <a
          class="relative text-transparent group-hover:text-black"
          :href="`#competency-${obj.competency.id}`"
          >{{ obj.competency.title }}
          <span
            class="absolute bg-gray-300 h-1 w-full left-0 top-2 rounded-lg group-hover:hidden block"
          />
        </a>

        <ul class="mt-2 ml-4">
          <li v-for="goal in obj.goals">
            <a
              class="inline-block overflow-hidden max-w-full whitespace-nowrap text-ellipsis"
              :href="`#goal-${goal.id}`"
            >
              <span class="relative">
                <span class="text-transparent group-hover:text-black">{{
                  goal.student.name
                }}</span>
                <span
                  class="block group-hover:hidden absolute bg-gray-300 h-1 w-full left-0 top-2 rounded-lg"
                  :class="goalState(goal)"
                />
              </span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </aside>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue'
  import { goalState, mapGoalsByCompetency } from '../utils.js'

  const props = defineProps({
    competencies: {
      type: Array as PropType<Competency[]>,
      required: true,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const goalsByCompetency = computed(() => {
    return mapGoalsByCompetency(props.competencies, props.session.goals)
  })
</script>

<style lang="postcss" scoped>
  aside:not(:hover) .success {
    @apply bg-lime-200;
  }

  aside:not(:hover) .fail {
    @apply bg-red-200;
  }

  aside:not(:hover) .semi-fail {
    @apply bg-orange-200;
  }

  aside:not(:hover) .no-result {
    @apply bg-sky-200;
  }
</style>
