<template>
  <div class="mt-6 first:mt-0">
    <card>
      <div
        class="px-4 pt-3 -mx-4 -mt-8 mb-4 border-t-4 border-t-sky-500"
        :class="state"
      >
        <session-goal-header
          v-if="post.goal"
          :goal="post.goal"
          :state="state"
          :show-name="false"
          :show-competency="post.goal.competency ? true : false"
        />
      </div>

      <template v-if="showSessionDetails">
        <dl class="my-4 text-xs text-gray-500">
          <dt class="font-bold">{{ $t('entities.session.startingAt') }}</dt>
          <dd class="mb-2">
            <router-link
              class="underline"
              :to="{
                name: 'session',
                params: { sessionId: post.goal.session.id },
              }"
              >{{
                formattedTimestamp(post.goal.session.startingAt)
              }}</router-link
            >
          </dd>
          <template v-if="post.goal.session.context">
            <dt class="font-bold">{{ $t('entities.session.context') }}</dt>
            <dd class="mb-2">{{ post.goal.session.context }}</dd>
          </template>
          <template v-if="post.goal.session.teachers">
            <dt class="font-bold">
              {{
                $t(
                  `entities.session.teachers.${
                    post.goal.session.teachers.length > 1
                      ? 'plural'
                      : 'singular'
                  }`,
                )
              }}
            </dt>
            <dd>
              {{
                post.goal.session.teachers.map((t: User) => t.name).join(', ')
              }}
            </dd>
          </template>
        </dl>
      </template>

      <template v-if="post.goal">
        <h3 class="mb-2 text-xl first-letter:capitalize">
          {{ $t('entities.goal.plural') }}
        </h3>
        <div v-html="post.goal.description" />
      </template>

      <h3 class="mt-4 mb-2 text-xl" v-if="post.goal">
        {{ $t('entities.session.feedback') }}
      </h3>
      <div class="post-body" v-html="post.body" />
    </card>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue'
  import { formattedTimestamp, goalState } from '../utils.js'

  import SessionGoalHeader from './SessionGoalHeader.vue'
  import Card from './Card.vue'

  const props = defineProps({
    post: {
      type: Object as PropType<Post>,
      required: true,
    },

    showSessionDetails: {
      type: Boolean,
      default: () => true,
    },
  })

  const state = computed(() => {
    if (props.post.goal) {
      return goalState(props.post.goal)
    }
  })
</script>

<style lang="postcss" scoped>
  .success {
    @apply border-lime-500;
  }

  .fail {
    @apply border-red-500;
  }

  .semi-fail {
    @apply border-orange-500;
  }

  .post-body {
    @apply space-y-4;
  }

  .post-body:deep(ul) {
    @apply list-disc ml-6;
  }
</style>
