<template>
  <div
    class="p-4 pt-0 bg-white border border-gray-200"
    :class="{ 'shadow-lg': enabled && current }"
  >
    <template v-if="goal">
      <div
        class="px-4 pt-3 -mx-4 mb-4 border-t-4 border-t-sky-500"
        :class="state"
      >
        <session-goal-header :goal="goal" :state="state" />
      </div>

      <h3 class="font-bold">Leerdoelen</h3>
      <div class="post-body mb-2" v-html="goal.description" />

      <p v-if="enabled && !focused" class="py-2 text-right">
        <router-link
          class="cursor-pointer text-sky-500"
          :to="{
            name: 'session_goal',
            params: { sessionId: goal.session.id, goalId: goal.id },
          }"
        >
          Bekijk eerdere feedback
        </router-link>
      </p>
    </template>

    <template v-else>
      <div class="px-4 pt-3 -mx-4 mb-4 border-t-4 border-t-sky-500">
        <header class="flex items-center text-xl">
          <span class="mr-6">Algemene feedback</span>
        </header>
      </div>
    </template>

    <div class="space-y-4">
      <session-goal-editor
        :current="current"
        :enabled="enabled"
        :goal="goal"
        :me="me"
        :post="post"
        :session="session"
        @save:post="emit('save:post', $event)"
        @set:current="current = $event"
      />

      <session-goal-attachments
        :enabled="enabled"
        :goal="goal"
        :post="post"
        @add:attachment="emit('add:attachment', $event)"
        @create:attachment="emit('create:attachment', $event)"
        @delete:attachment="emit('delete:attachment', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed, ref } from 'vue'
  import SessionGoalHeader from './SessionGoalHeader.vue'
  import SessionGoalEditor from './SessionGoalEditor.vue'
  import SessionGoalAttachments from './SessionGoalAttachments.vue'

  import { goalState } from '../utils.js'

  const props = defineProps({
    enabled: {
      type: Boolean,
      required: true,
    },

    focused: {
      type: Boolean,
      default: false,
    },

    goal: {
      type: Object as PropType<Goal>,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },

    post: {
      type: Object as PropType<Post>,
      default: () => {
        return {}
      },
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const emit = defineEmits([
    'add:attachment',
    'create:attachment',
    'delete:attachment',
    'save:post',
    'set:focused',
  ])
  const current = ref(false)
  const state = computed(() => {
    if (props.goal) {
      return goalState(props.goal)
    } else {
      return null
    }
  })
</script>

<style lang="postcss" scoped>
  .success {
    @apply border-lime-500;
  }

  .fail {
    @apply border-red-500;
  }

  .semi-fail {
    @apply border-orange-500;
  }

  .post-body {
    @apply space-y-4;
  }

  .post-body:deep(ul) {
    @apply list-disc ml-6;
  }
</style>
