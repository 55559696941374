<template>
  <div class="relative">
    <p>
      <input
        v-model="searchQuery"
        type="search"
        :placeholder="`Zoek ${fieldTitle}…`"
        autofocus
      />
    </p>

    <div
      v-if="foundUsers.length"
      class="overflow-auto absolute p-2 w-full max-h-60 bg-white rounded-md border-t shadow-md border-t-gray-100"
    >
      <user-search-results :users="foundUsers" @select:user="addUser($event)" />
    </div>

    <div v-if="selectedUsers.length" class="mt-4">
      <h3 class="text-xs font-semibold">
        Geselecteerde {{ fieldTitle }} ({{ selectedUsers.length }})
      </h3>

      <user-search-results
        :me="me"
        :removable="true"
        :users="selectedUsers"
        @remove:user="removeUser($event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed, reactive, ref, watch } from 'vue'
  import { useQuery } from 'villus'

  import StudentQuery from '../graphql/Students.graphql'
  import TeacherQuery from '../graphql/Teachers.graphql'

  import UserSearchResults from './UserSearchResults.vue'

  const props = defineProps({
    field: {
      type: String as PropType<'students' | 'teachers'>,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
    },

    session: {
      type: Object as PropType<NewSession>,
      required: true,
    },
  })

  const emit = defineEmits(['update:value'])
  const selectedUsers = ref<User[]>(props.session[props.field] || [])
  const fieldTitle = props.field === 'students' ? 'studenten' : 'assessoren'

  const addUser = (user: User) => {
    selectedUsers.value.push(user)
    searchQuery.value = ''
  }
  const removeUser = (user: User) => {
    const idx = selectedUsers.value.indexOf(user)
    selectedUsers.value.splice(idx, 1)
  }

  const searchQuery = ref('')
  const query = props.field === 'students' ? StudentQuery : TeacherQuery
  const variables = reactive({ query: searchQuery })

  const { isDone, data } = useQuery({ query, variables })

  const foundUsers = computed<User[]>(() => {
    if (isDone.value) {
      const users = data.value[props.field as keyof Object] as User[]
      const selectedUserIds = selectedUsers.value.map((u) => u.remoteId)
      return users.filter((u) => !selectedUserIds.includes(u.remoteId))
    } else {
      return []
    }
  })

  watch(
    () => [...selectedUsers.value],
    (value: User[]) => {
      const field = props.field
      emit('update:value', { field, value })
    }
  )
</script>
