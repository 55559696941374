<template>
  <aside
    class="p-4 pb-0 space-y-4 md:pd-4 md:fixed md:space-y-7 md:w-60 md:h-screen md:flex md:flex-col"
  >
    <div class="flex items-center">
      <div>
        <h2 class="font-bold">{{ tenant.name }}</h2>
        <h3>{{ me.name }}</h3>
      </div>

      <div class="ml-auto">
        <div
          class="cursor-pointer p-1"
          :class="{ 'bg-gray-200 rounded-md text-sky-500': showAppList }"
          @click="showAppList = !showAppList"
        >
          <squares-2-x-2-icon class="w-6" />
        </div>
      </div>
    </div>

    <app-picker v-if="showAppList" :me="me" />

    <nav class="md:flex-grow md:flex md:flex-col">
      <a
        class="flex items-center mb-3 cursor-pointer md:cursor-text"
        @click="showMenu = !showMenu"
      >
        <h4 class="font-bold">Menu</h4>
        <span class="w-5 ml-auto md:hidden">
          <chevron-down-icon v-if="showMenu" />
          <chevron-right-icon v-else />
        </span>
      </a>

      <div class="md:block md:flex-grow" :class="{ hidden: !showMenu }">
        <ul class="md:flex md:flex-col md:h-full md:pb-4">
          <li v-if="me.kind == 'teacher'">
            <pill-button
              :title="$t('entities.student.plural')"
              :route="{ name: 'groups' }"
              class-names="light full-width"
              :icon="ArrowTrendingUpIcon"
            />
          </li>

          <template v-else>
            <li>
              <pill-button
                :title="$t('features.progress')"
                :route="{ name: 'student', params: { studentId: me.id } }"
                class-names="light full-width"
                :icon="ArrowTrendingUpIcon"
              />

              <ul class="nested">
                <li
                  v-for="competency in competencies"
                  :key="`sidebar-competency-${competency.id}`"
                >
                  <pill-button
                    :title="
                      locale === 'en' ? competency.titleEn : competency.title
                    "
                    :route="{
                      name: 'student_competency',
                      params: { studentId: me.id, competencyId: competency.id },
                    }"
                    class-names="light full-width"
                  />
                </li>
              </ul>
            </li>
          </template>

          <li>
            <pill-button
              :title="$t('features.sessions')"
              :route="{ name: 'sessions' }"
              class-names="light full-width"
              :icon="ClipboardIcon"
            />
          </li>

          <li class="mt-auto">
            <ul>
              <li v-if="me.admin">
                <pill-button
                  :title="$t('features.admin')"
                  :route="{ name: 'admin' }"
                  class-names="light full-width"
                  :icon="Cog8ToothIcon"
                />
              </li>

              <li>
                <pill-button
                  :title="$t('session.logout')"
                  :external="logoutUrl"
                  class-names="light full-width"
                  :icon="ArrowRightOnRectangleIcon"
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </aside>
</template>

<script lang="ts" setup>
  import { PropType, ref, watch } from 'vue'
  import { useRouter } from 'vue-router'

  import PillButton from './PillButton.vue'
  import AppPicker from './AppPicker.vue'
  import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
  import {
    ArrowTrendingUpIcon,
    ClipboardIcon,
    ArrowRightOnRectangleIcon,
    Cog8ToothIcon,
    Squares2X2Icon,
  } from '@heroicons/vue/24/outline'
  import { useI18n } from 'vue-i18n'

  const logoutUrl = import.meta.env.VITE_APP_LOGOUT_URL
  const showMenu = ref(false)
  const showAppList = ref(false)
  const router = useRouter()

  const { locale } = useI18n()

  watch(router.currentRoute, () => {
    showMenu.value = false
  })

  defineProps({
    competencies: {
      required: true,
      type: Array as PropType<Competency[]>,
    },

    me: {
      required: true,
      type: Object as PropType<User>,
    },

    tenant: {
      required: true,
      type: Object as PropType<Tenant>,
    },
  })
</script>

<style scoped>
  li ul.nested {
    display: none;
    margin-left: 15px;
  }

  .router-link-active ~ ul.nested {
    display: block;
  }
</style>
