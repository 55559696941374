<template>
  <div
    :class="classNames"
    class="flex relative px-4 mt-1 rounded-md first:mt-0 hover:bg-sky-100 items-center"
    @click="toggleOptions"
  >
    <slot />

    <icon-button
      v-if="isEnabled && goal.result"
      class="ml-auto"
      :icon="XCircleIcon"
      @click="deleteResult"
    />
  </div>

  <result-picker-options
    v-if="showOptions"
    :goal="goal"
    @create:result="createResult"
    @dismiss="showOptions = false"
  />
</template>

<script lang="ts" setup>
  import { PropType, ref, computed } from 'vue'
  import ResultPickerOptions from './ResultPickerOptions.vue'
  import IconButton from './IconButton.vue'
  import { XCircleIcon } from '@heroicons/vue/24/solid'
  import { goalState } from '../utils.js'

  const props = defineProps({
    goal: {
      type: Object as PropType<Goal>,
      required: true,
    },

    isEnabled: {
      type: Boolean,
      default: true,
    },
  })

  const showOptions = ref(false)
  const emit = defineEmits(['create:result', 'delete:result'])

  const classNames = computed(() => {
    const classNames = [goalState(props.goal)]
    if (props.isEnabled) {
      classNames.push('cursor-pointer')
    } else {
      classNames.push('cursor-default')
    }

    return classNames.join(' ')
  })

  const createResult = (result: Result) => {
    emit('create:result', result)
    showOptions.value = false
  }

  const toggleOptions = (evt: MouseEvent) => {
    console.log(evt.target)

    if (props.isEnabled) {
      showOptions.value = true
    }
  }

  const deleteResult = () => {
    setTimeout(() => (showOptions.value = false), 10)
    emit('delete:result', props.goal.result)
  }
</script>

<style lang="postcss" scoped>
  .success {
    @apply bg-lime-100;
  }

  .semi-fail {
    @apply bg-orange-100;
  }

  .no-result {
    @apply bg-sky-100;
  }

  .fail {
    @apply bg-red-100;
  }
</style>
